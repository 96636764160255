import React, { useState } from 'react';
import {
  Box,
  Card,
  Container,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { GetUsersPerPageResponse } from '../../../requests/api/apiTypes';
import useStyles from './styles';
import useFetchApi from '../../../hooks/useFetchApi';
import Page from '../../../components/Layout/Page';
import CustomHeader from '../../../components/General/CustomHeader';
import CustomSearch from '../../../components/General/CustomSearch';
import CustomTable from '../../../components/General/CustomTable';
import { ReduxState } from '../../../types';

const USERS_LIST_UI = [
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'Apellido', key: 'lastname',
  },
  {
    label: 'Correo', key: 'email',
  },
  {
    label: 'Teléfono', key: 'phone',
  },
  {
    label: 'Rol', key: 'role',
  },
];

const ClientUserListView = () => {
  const classes = useStyles();
  const [paginationParameters, setPaginationParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [query, setQuery] = useState('');
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);

  const handleQuery = (valueToSearch: string) => {
    setQuery(valueToSearch);
    setPaginationParameters((prevState) => ({ ...prevState, page: 0 }));
  };

  const { data: fetchData } = useFetchApi<GetUsersPerPageResponse>({
    modelEndpoint: 'usersList',
    modelName: 'users',
    dependencies: [
      paginationParameters.page, paginationParameters.rowsPerPage, query, selectedAccount?.id ?? '',
    ],
    inputData: {
      page: paginationParameters.page,
      limit: paginationParameters.rowsPerPage,
      searchValue: query,
      filterAccount: selectedAccount?.id ?? '',
    },
  });

  const localData = {
    count: fetchData?.totalUsers ?? 0,
    values: fetchData?.users?.map((elem) => ({
      name: elem.name,
      lastname: elem.lastname,
      email: elem.email,
      phone: elem.phoneNumber ?? '-',
      role: elem.role,
    })) ?? [],
  };

  return (
    <Page
      className={classes.root}
      title='Usuarios'
    >
      <Container maxWidth={false}>
        <CustomHeader title='Usuarios' />
        <Box mt={3}>
          <Card>
            <CustomSearch query={query} handleQuery={handleQuery} />
            <CustomTable
              rows={{ values: localData.values, count: localData.count }}
              headers={USERS_LIST_UI}
              paginationParameters={paginationParameters}
              setPaginationParameters={setPaginationParameters}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ClientUserListView;
