import { PostUserSchema } from '../requests/api/apiTypes';

export const SET_CREATE_USER_DATA = '@user/create-user-data';
export const RESET_CREATE_USER_DATA = '@user/reset-create-user-data';

export const setCreateUserData = (userData: Partial<PostUserSchema>) => ({
  type: SET_CREATE_USER_DATA,
  payload: {
    ...userData,
  },
}

);

export const resetCreateUserData = () => ({
  type: RESET_CREATE_USER_DATA,
});
