import { UserSchema } from '../../../requests/api/apiTypes';

const REQUIRED_TEXT_INPUT = ['name', 'lastname', 'email'];
const FIELDS_EQ = {
  name: 'Nombre',
  lastname: 'Apellidos',
  email: 'Correo',
  type: 'Tipo',
  role: 'Rol',
};
const REQUIRED_SELECT_INPUT = ['role', 'type'];

type RequiredFields = 'name' | 'lastname' | 'email' | 'role' | 'type';

interface UserSchemaReduced extends Pick<UserSchema, 'name' | 'lastname' | 'email' | 'company' | 'phoneNumber' | 'role' | 'type' | 'active'> {
  accounts?: Array<{
    id: string;
    farms: string[];
    name: string;
  }>;
}

export {
  REQUIRED_TEXT_INPUT,
  FIELDS_EQ,
  REQUIRED_SELECT_INPUT,
  RequiredFields,
  UserSchemaReduced,
};
