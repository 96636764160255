import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const paddingPage = '20px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: paddingPage,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '14px',
    },
  },
  loginContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  loginFormContainer: {
    textAlign: 'center',
    width: '40%',
    marginRight: '30px',
  },
  imageContainer: {
    maxHeight: `calc(100vh - 2 * ${paddingPage})`,
    width: '60%',
    display: 'flex',
  },
  imageBackground: {
    width: '100%',
    height: 'auto',
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
  },
  googleButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  googleButton: {
    backgroundColor: 'white',
    border: '1px solid black',

  },
  resetPassword: {
    display: 'flex',
    textTransform: 'none',
    color: theme.palette.text.primary,
    margin: '1rem auto 0px auto',
  },
}));

export default useStyles;
