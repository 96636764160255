const CDTEC_USER_TYPE = 'cdtec';
const CONSULTANT_USER_TYPE = 'consultant';
const CLIENT_USER_TYPE = 'client';
const MULTI_ACCOUNT_CLIENT_USER_TYPE = 'multiAccountClient';

const USER_ADMIN_ROLE = 'admin';

export {
  CDTEC_USER_TYPE,
  CONSULTANT_USER_TYPE,
  CLIENT_USER_TYPE,
  MULTI_ACCOUNT_CLIENT_USER_TYPE,
  USER_ADMIN_ROLE,
};
