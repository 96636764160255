import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import useStyles from '../styles';
import CustomButton from '../../../../components/General/CustomButton';
import ListTile from './ListTile';
import GoogleMaps from './GoogleMaps';
import { ReduxState } from '../../../../types';
import { filteredDevices } from '../helpers';

const AddPolygons = ({
  title,
  detailedInfoTitle,
  detailedInfo,
  handleCloseModal,
  handleGoBack,
  lat,
  lng,
  insertFunction,
  handleCenterChange,
  handleZoomChange,
  enforcePolygon,
  buttonDisabled,
}: {
  title: string,
  detailedInfoTitle: string,
  detailedInfo: {
    [key: string]: string | number,
  },
  handleCloseModal: VoidFunction,
  handleGoBack: VoidFunction,
  lat?: string,
  lng?: string,
  insertFunction: (coordinates?: { lng: number, lat: number }[] | null) => void,
  handleCenterChange?: (centerCoordinates: {
    lat: () => string;
    lng: () => string;
  }) => void,
  handleZoomChange?: (zoom: number) => void,
  enforcePolygon: boolean,
  buttonDisabled: boolean,
}) => {
  const classes = useStyles();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { allFarmDevices, sensorNodeFilter } = useSelector((state: ReduxState) => state.farmSector);
  const [coordinates, setCoordinates] = useState<{ lng: number, lat: number }[] | null>([]);

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    })) : null;

  return (
    <Box display='flex' flexDirection='column' flex='1'>
      <Box className={classes.uploadKMZModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.uploadKMZModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {title}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      <ListTile
        title={detailedInfoTitle}
        tileInformation={detailedInfo}
      />
      <Box border='1px solid black' flex='1'>
        <GoogleMaps
          farm={{
            id: selectedFarm?.id ?? '',
            coordinates: farmCoordinates,
            zoom: selectedFarm?.zoom ?? 18,
          }}
          lat={lat ?? ''}
          lng={lng ?? ''}
          drawable='polygon'
          polygons={selectedFarm?.sectors ?? []}
          setCoordinates={setCoordinates}
          markers={filteredDevices(allFarmDevices, sensorNodeFilter)}
          handleCenterChange={handleCenterChange}
          handleZoomChange={handleZoomChange}
        />
      </Box>
      <Box className={classes.addPolygonButtonContainer}>
        <Box className={classes.singleButtonContainer} marginRight='20px'>
          <CustomButton buttonText='atras' onClick={handleGoBack} />
        </Box>
        {!enforcePolygon && <Box className={classes.singleButtonContainer} marginRight='20px'>
          <CustomButton buttonText='Agregar sin poligono'
            onClick={insertFunction} color='lightBlue' disabled={buttonDisabled} />
        </Box>}
        <Box className={classes.singleButtonContainer}>
          <CustomButton buttonText='Agregar' onClick={() => insertFunction(coordinates)} color='lightBlue' disabled={buttonDisabled} />
        </Box>
      </Box>
    </Box>
  );
};

export default AddPolygons;
