import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    marginRight: '15px',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  noDataDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '250px',
  },
  iconContainer: {
    height: '60px',
    width: '60px',
  },
  tableHeaderText: {
    fontWeight: 'bold',
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  formContainer: {
    marginTop: '24px',
  },
  greenButton: {
    backgroundColor: '#54B03A',
    '&:hover': {
      backgroundColor: '#438c2e',
      color: '#e6e8e6',
    },
  },
  cardContainer: {
    marginTop: '30px',
  },
}));

export default useStyles;
