import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { Close as CloseIcon, LinkOff as LinkOffIcon, Link as LinkIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useStyles from '../styles';
import CustomButton from '../../../../components/General/CustomButton';
import { ReduxState } from '../../../../types';
import ListTile from './ListTile';
import ListLayout from './ListLayout';
import GoogleMaps from './GoogleMaps';
import apiClient from '../../../../requests/api/apiClient';
import { setAccountFarm, setAccountFarmsOptions } from '../../../../actions/accountFarmAction';
import { FarmSchema, PutSectorsKMZSchema } from '../../../../requests/api/apiTypes';
import { FetchError } from '../../../types';
import { KMZPolygons } from '../types';

const UploadKMZ = ({
  handleCloseModal,
  polygons,
  KMZSector,
  cdtecSector,
  setCdtecSector,
  mappedSectors,
}: {
  handleCloseModal: VoidFunction,
  polygons: KMZPolygons | null,
  KMZSector: PutSectorsKMZSchema[string] | null,
  setCdtecSector: React.Dispatch<React.SetStateAction<string | null>>,
  cdtecSector: string | null,
  mappedSectors: PutSectorsKMZSchema
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedFarm, farmsOptions } = useSelector((state: ReduxState) => state.accountFarm);

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    })) : null;

  const updateKMZSectors = async () => {
    try {
      const { data } = await apiClient.sectorsKmz.sectorsKmzUpdate(mappedSectors);

      const relevantFarm = farmsOptions?.find((farm: FarmSchema) => farm.id === selectedFarm?.id)
        ?? {};
      const auxFarmOptions = farmsOptions
        ?.filter((farm: FarmSchema) => farm.id !== selectedFarm?.id) ?? [];
      dispatch(setAccountFarmsOptions([
        ...auxFarmOptions,
        {
          ...relevantFarm as FarmSchema,
          sectors: data.sectors,
        },
      ]));
      const relevantFarmToModify = selectedFarm as FarmSchema;
      dispatch(setAccountFarm({
        ...relevantFarmToModify,
        sectors: data.sectors,
      }));

      handleCloseModal();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      if (fetchError?.message) {
        enqueueSnackbar(fetchError?.message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    }
  };

  return (
    <Box display='flex' flexDirection='column' flex='1'>
      <Box className={classes.uploadKMZModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.uploadKMZModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Subir .KMZ
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      <Typography
        variant='body1'
        fontWeight='bold'
      >
        Vincula los polígonos a sus respectivos sectores
      </Typography>
      <Typography
        variant='body2'
      >
        Haz click en un polígono o sector de la lista y luego clickea en su respectiva
        contraparte para vincularulos.
      </Typography>
      <Typography
        variant='body2'
      >
        Los polígonos que no sean vinculados serán agregados
        como sectores sin información los cuales podrán ser rellenados en cualquier momento
        por el usuario.
      </Typography>
      <Divider className={classes.divider} />
      {selectedFarm && <Box display='flex' flex={1} mt={2}>
        <Box border='1px solid black' flex={1} mr={3}>
          <GoogleMaps
            drawable='none'
            lat={selectedFarm.center.lat}
            lng={selectedFarm.center.lng} farm={{
              id: selectedFarm?.id ?? '',
              coordinates: farmCoordinates,
              zoom: selectedFarm?.zoom ?? 18,
            }}
            polygons={polygons ? polygons.map((polygon) => ({
              ...polygon,
              isSelected: polygon.id === KMZSector?.id,
              isLinked: Object.values(mappedSectors).some((elem) => elem.id === polygon.id),
            })) : []}
          />
        </Box>
        <Box minWidth='290px'>
          <ListLayout>
            <Typography
              variant="h3"
              color="primary"
            >
              Sectores
            </Typography>
            <Divider className={classes.divider} />
            <Box className={classes.listTilesContainer}>
              {(selectedFarm?.sectors && selectedFarm.sectors.length > 0)
                && selectedFarm.sectors.map((sector) => {
                  const {
                    id, name, subSectors, ...rest
                  } = sector;
                  return (
                    <React.Fragment key={sector.id}>
                      <ListTile
                        title={name}
                        tileInformation={{
                          ID: rest.wiseconnId ?? '',
                          'Superficie Teórica': rest.theoreticalSurface,
                          // 'Superficie Real': rest.realSurface,
                          'Tipo de suelo': rest.typeOfSoil,
                        }}
                        isActive={cdtecSector === id}
                        onClick={() => setCdtecSector(sector.id as string)}
                        customContainerClassName={classes.actionableListTileContainer}
                        customSeeMoreIcon={Object.hasOwnProperty.call(mappedSectors, id as string)
                          ? <IconButton><LinkIcon /></IconButton>
                          : <IconButton><LinkOffIcon /></IconButton>}
                      />
                      <Divider />
                    </React.Fragment>

                  );
                })}
              {(selectedFarm?.sectors && selectedFarm.sectors.length === 0)
                && <Typography variant='body2'>
                  No hay sectores asociados a este campo
                </Typography>}
            </Box>
          </ListLayout>
        </Box>
      </Box>}
      <Box className={classes.buttonContainer}>
        <Box className={classes.singleButtonContainer} marginRight='20px'>
          <CustomButton buttonText='cancelar' onClick={handleCloseModal} />
        </Box>
        <Box className={classes.singleButtonContainer}>
          <CustomButton buttonText='Agregar sectores' onClick={updateKMZSectors} color='lightBlue' />
        </Box>
      </Box>
    </Box>
  );
};

export default UploadKMZ;
