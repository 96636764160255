import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import TopBar from './TopBar';

import useStyles from './styles';
import { ChildrenProps } from '../../types';

function DashboardLayout({ children }: ChildrenProps) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openDrawer={isDrawerOpen}
        onDrawerAction={() => setIsDrawerOpen((prevState) => !prevState)}
      />
      <div className={isDrawerOpen ? classes.wrapperWithNavbar : classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
