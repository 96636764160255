import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

const listTileContainerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  // '&:hover': {
  //   cursor: 'pointer',
  // },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  containerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',
  },
  mainAreaContainer: {
    width: '100%',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  // List Layout
  cardListContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
  cardListContentContainer: {
    width: '100%',
    // overflowY: 'auto',
  },
  list: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  // List Tile
  listTileContainer: {
    ...listTileContainerStyle,
  },
  actionableListTileContainer: {
    padding: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeListTileContainer: {
    ...listTileContainerStyle,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.white,
  },
  listTilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    padding: '0px 5px',
  },
  listTileTextContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listTileSeeMoreContainer: {
    position: 'absolute',
    right: '15px',
  },
  divider: {
    margin: '5px 0px',
    width: '100%',
  },
  // layoutMap
  cardLayoutContainer: {
    display: 'flex',
    flex: '1',
  },
  cardLayoutContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeaderLayoutContentContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  googleMapsContainer: {
    flex: '1',
    border: '1px solid black',
    height: '100%',
    marginTop: '10px',
  },
  layoutMapChip: {
    marginRight: '10px',
  },
  // uploadKMZModal
  uploadKMZModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  uploadKMZModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
  },
  // AddDevicesModal
  // Add Polygon Modal
  addPolygonButtonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '10px',
  },
  // AddDevicesFlowSecondStep
  checkboxContainer: {
    width: '100%',
    marginRight: '20px',
    display: 'flex',
  },
  // AddDevicesFlowThirdStep
  addDevicesFlowListTileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  addDevicesFlowListTileAndTextContainer: {
    display: 'flex',
  },
  typographyNodeTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginLeft: '15px',
  },
  typographyNodeTitle: {
    maxWidth: '540px',
  },
  // Common
  uploadAddDevicesHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  uploadAddDevicesHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textFieldsContainer: {
    display: 'flex',
    marginBottom: '15px',
  },
  singleLeftTextfield: {
    marginRight: '20px',
  },
  singleTextFieldsContainer: {
    width: '49%',
    marginBottom: '15px',
  },
  singleButtonContainer: {
    width: '290px',
  },
  cardModalContainer: {
    height: '85%',
  },
  uploadImagesContainer: {
    width: '100%',
    marginBottom: '15px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 0px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  // uploadImageText: {
  //   color: theme.palette.primary.main,
  //   '&:hover': {
  //     color: 'white',
  //   },
  // },
  // uploadImagesIcon: {
  //   color: theme.palette.primary.main,
  //   '&:hover': {
  //     color: 'white',
  //   },
  // },
  lateralListsContainer: {
    maxWidth: '290px',
    width: '100%',
  },
}));

export default useStyles;
