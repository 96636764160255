/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuidv4 } from 'uuid';
import useStyles from '../styles';
import CustomButton from '../../../components/General/CustomButton';
import useFetchApi from '../../../hooks/useFetchApi';
import { GetSpeciesResponse, GetVarietyByIdResponse, VarietySchema } from '../../../requests/api/apiTypes';
import MonthDayPicker from '../../../components/General/MonthDayPicker';

type LocalPhenologicalStates = {
  id: string,
  name: string,
  startDate: string | null,
  endDate: string | null,
};

type Errors = {
  [key: string]: {
    message: string,
    active?: boolean,
    stateIds?: string[],
  }
};

const PhenologicalStateComponent = ({
  elem, onValueChange, error, removePhenologicalState,
}: {
  elem: LocalPhenologicalStates,
  onValueChange: (id: string, value: string, source: 'name' | 'startDate' | 'endDate') => void,
  error: Errors,
  removePhenologicalState: (item: string) => void,
}) => (
  <TableRow >
    <TableCell>
      <TextField
        label='Nombre'
        name="name"
        variant='standard'
        value={elem.name ?? ''}
        onChange={(e) => onValueChange(elem.id, e.target.value, 'name')}
        type='text'
        required
        error={error.name.stateIds?.includes(elem.id)}
        helperText={error.name.stateIds?.includes(elem.id) ? error.name.message : '' } // Revisar funcionamiento de esto
      />
    </TableCell>
    <TableCell>
      <MonthDayPicker
        label="Inicio"
        value={elem.startDate ?? ''}
        onChange={(value: string) => onValueChange(elem.id, value, 'startDate')}
        error={error.startDate.stateIds?.includes(elem.id) ?? false}
        helperText={error.startDate.stateIds?.includes(elem.id) ? error.startDate.message : 'Formato: MM-DD'} // Revisar funcionamiento de esto
      />
    </TableCell>
    <TableCell>
      <MonthDayPicker
        label="Fin"
        value={elem.endDate ?? ''}
        onChange={(value: string) => onValueChange(elem.id, value, 'endDate')}
        error={error.endDate.stateIds?.includes(elem.id) ?? false}
        helperText={error.endDate.stateIds?.includes(elem.id) ? error.endDate.message : 'Formato: MM-DD'} // Revisar funcionamiento de esto
      />
    </TableCell>
    <TableCell >
      <IconButton onClick={() => removePhenologicalState(elem.id)}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const UpsertModalVarietyContent = ({
  handleCloseModal,
  handleUpsertFunction,
  id,
  isLoadingUpsert,
}: {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: ({
    name,
    species,
    phenologicalStates,
    id,
  }: {
    name: string,
    species: string,
    phenologicalStates: VarietySchema['phenologicalStates'],
    id?: string,
  }) => void,
  id?: string,
  isLoadingUpsert: boolean,
}) => {
  const classes = useStyles();

  const [varietyName, setVarietyName] = useState('');
  const [speciesName, setSpeciesName] = useState('');
  const [localPhenologicalStates, setLocalPhenologicalStates] = useState<LocalPhenologicalStates[]
  >([]);
  const [beenUpdated, setBeenUpdated] = useState(false);
  const [speciesErrors, setSpeciesErrors] = useState({
    varietyName: {
      message: '',
      active: false,
    },
    speciesName: {
      message: '',
      active: false,
    },
  });

  const [phenologicalStateErrors, setPhenologicalStateErrors] = useState({
    name: {
      message: '',
      stateIds: [] as string[],
    },
    startDate: {
      message: '',
      stateIds: [] as string[],
    },
    endDate: {
      message: '',
      stateIds: [] as string[],
    },
  });

  const { data } = useFetchApi<GetSpeciesResponse>({
    modelName: 'species',
    modelEndpoint: 'speciesList',
  });

  const entityToModify = id && useFetchApi<GetVarietyByIdResponse>({
    modelName: 'varieties',
    modelEndpoint: 'varietiesDetail',
    id,
  });

  useEffect(() => {
    if (id && entityToModify && entityToModify.data && !beenUpdated) {
      const auxPhenologicalStates = entityToModify.data.variety?.phenologicalStates
        ?.map((elem) => ({
          startDate: elem.startDate,
          endDate: elem.endDate,
          name: elem.name,
          id: uuidv4(),
          disabled: true,
        })) ?? [];
      setBeenUpdated(true);
      setVarietyName(entityToModify.data.variety?.name ?? '');
      setSpeciesName(entityToModify.data.variety?.species ?? '');
      setLocalPhenologicalStates(auxPhenologicalStates);
    }
  }, [entityToModify]);

  const isPhenologicalStateComplete = (phenologicalState: LocalPhenologicalStates) => {
    if (phenologicalState.name.trim() === '') {
      setPhenologicalStateErrors((prevState) => ({ ...prevState, name: { stateIds: [...prevState.name.stateIds, phenologicalState.id], message: 'Nombre no puede estar vacio' } }));
      return false;
    }

    if (!phenologicalState.startDate) {
      setPhenologicalStateErrors((prevState) => ({ ...prevState, startDate: { stateIds: [...prevState.startDate.stateIds, phenologicalState.id], message: 'Fecha de inicio no puede estar vacia' } }));
      return false;
    }

    if (!phenologicalState.endDate) {
      setPhenologicalStateErrors((prevState) => ({ ...prevState, endDate: { stateIds: [...prevState.endDate.stateIds, phenologicalState.id], message: 'Fecha de inicio no puede estar vacia' } }));
      return false;
    }

    return true;
  };

  const handleAddPhenologicalState = () => {
    let allValid = true;

    localPhenologicalStates.forEach((state) => {
      const isValid = isPhenologicalStateComplete(state);
      if (!isValid) {
        allValid = false;
      }
    });

    if (allValid) {
      setLocalPhenologicalStates((prevPhenologicalStates) => [
        ...prevPhenologicalStates,
        {
          id: uuidv4(),
          name: '',
          startDate: null,
          endDate: null,
        },
      ]);
    }
  };

  const removePhenologicalState = (phenologicalStateId: string) => {
    setLocalPhenologicalStates((prevState) => prevState
      .filter((elem) => elem.id !== phenologicalStateId));
  };

  const onValueChange = (
    phenStateId: string,
    value: string,
    source: keyof typeof phenologicalStateErrors,
  ) => {
    setLocalPhenologicalStates((prevStates) => prevStates.map((state) => (
      state.id === phenStateId ? { ...state, [source]: value } : state
    )));

    setPhenologicalStateErrors((prevState) => ({
      ...prevState,
      [source]: {
        ...prevState[source],
        stateIds: prevState[source].stateIds.filter((stateId: string) => stateId !== phenStateId),
      },
    }));
  };

  const handleSubmitUpsert = () => {
    if (speciesName.trim() === '') {
      setSpeciesErrors((prevState) => ({ ...prevState, speciesName: { message: 'Debes elegir una especia a asociar', active: true } }));
      return;
    }

    if (varietyName.trim() === '') {
      setSpeciesErrors((prevState) => ({ ...prevState, varietyName: { message: 'Nombre no puede estar vacío', active: true } }));
      return;
    }

    let allValid = true;

    localPhenologicalStates.forEach((state) => {
      const isValid = isPhenologicalStateComplete(state);
      if (!isValid) {
        allValid = false;
      }
    });

    if (!allValid) {
      return;
    }

    handleUpsertFunction({
      name: varietyName,
      species: speciesName,
      phenologicalStates: localPhenologicalStates.filter((elem) => elem.name)
        .map((elem) => ({
          startDate: elem.startDate as string,
          endDate: elem.endDate as string,
          name: elem.name,
        })),
      id,
    });
  };

  return (
    <Box className={classes.upsertVarietyModalContainer}>
      <Box className={classes.upsertVarietyModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertVarietyModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {id ? 'Editar variedad' : 'Agregar variedad'}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Box className={classes.upsertVarietyNameSpeciesContainer}>
        <Box width='100%' marginRight='10px'>
          <Autocomplete
            options={data?.species ?? []}
            getOptionLabel={(option) => option.name ?? ''}
            value={data?.species?.find((s) => s.name === speciesName) || null}
            onChange={(_, newValue) => {
              setSpeciesErrors((prevState) => ({ ...prevState, speciesName: { message: '', active: false } }));
              setSpeciesName(newValue?.name ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Especie'
                fullWidth
                required
                error={speciesErrors.speciesName.active}
                helperText={speciesErrors.speciesName.active && speciesErrors.speciesName.message}
              />
            )}
          />
        </Box>
        <Box width='100%' marginLeft='10px'>
          <TextField
            variant='standard'
            label='Nombre'
            fullWidth
            required
            error={speciesErrors.varietyName.active}
            helperText={speciesErrors.varietyName.active && speciesErrors.varietyName.message}
            value={varietyName}
            onChange={(e) => {
              setSpeciesErrors((prevState) => ({ ...prevState, varietyName: { message: '', active: false } }));
              setVarietyName(e.target.value);
            }}
          />
        </Box>

      </Box>
      <Box className={classes.upsertVarietyModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertVarietyModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Estados fenológicos
          </span>
        </Typography>
        {/* added disabled button to align titles */}
        <IconButton disabled />
      </Box>
      <PerfectScrollbar>
        <TableContainer className={classes.tableScrollbarContainer} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  Nombre Estado
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Fecha inicio
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Fecha fin
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {localPhenologicalStates.map((elem) => (
                <PhenologicalStateComponent
                  key={elem.id}
                  elem={elem}
                  onValueChange={onValueChange}
                  error={phenologicalStateErrors}
                  removePhenologicalState={removePhenologicalState}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <CustomButton color='blue' buttonText='+ Agregar estado fenológico' onClick={handleAddPhenologicalState} />
      <Box className={classes.upsertVarietyModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}>
          <CustomButton
            color='green'
            buttonText={id ? 'Editar' : 'Agregar'}
            onClick={() => handleSubmitUpsert()}
            disabled={isLoadingUpsert}
            setLoadingSpinnerWhenDisabled={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UpsertModalVarietyContent;
