import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import useStyles from '../../styles';
import CustomButton from '../../../../../components/General/CustomButton';
import CustomModal from '../../../../../components/General/CustomModal';
import AddDevicesFlowSecondStep from './AddDevicesFlowSecondStep';
import AddDevicesFlowThirdStep from './AddDevicesFlowThirdStep';
import { ReduxState } from '../../../../../types';
// import apiClient from '../../../../../requests/api/apiClient';
import {
  DeviceSchema,
  GetDispatchGuidesByAccountIdResponse,
  PostNodeDispatchGuideSchema,
  PostNodeSchema,
  PostSensorDispatchGuideSchema,
  PostSensorSchema,
} from '../../../../../requests/api/apiTypes';
import {
  DeviceInfo,
  ErrorState,
  keysPostNodeSchema,
  keysPostSensorDispatchGuideSchema,
  keysPostNodeDispatchGuideSchema,
  keysPostSensorSchema,
} from './types';
import { setAllFarmDevices, setFarmDevice } from '../../../../../actions/farmSectorActions';
import useFetchApi from '../../../../../hooks/useFetchApi';
import axios from '../../../../../utils/axios';

const initialDeviceInfoState = {
  // firstStepInfo
  deviceType: '',
  farm: '',
  wiseconnId: '',
  subSector: '',
  dispatchGuideId: '',
  // SecondStepPossibleInfo
  // -- Common
  comment: '',
  brand: '',
  model: '',
  cdtecDeviceId: '',
  nodeId: '', // for node and for sensor association with node
  // -- sensor common
  sensorId: '',
  // -- sensor & !dispatchGuide
  sensorType: '',
  // -- sensor & dispatchGuide
  // -- node common
  isGatewayNode: false,
  nodeChip: '',
  // -- node & !dispatchGuide
  nodeType: '',
  // thirdStep
  coordinates: null,
};

const AddDevicesFlow = ({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { allFarmDevices } = useSelector((state: ReduxState) => state.farmSector);
  const [steps, setSteps] = useState({
    secondStep: false,
    thirdStep: false,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({ ...initialDeviceInfoState });
  const [error, setError] = useState<ErrorState>({
    deviceType: '',
    farm: '',
    sensorType: '',
    sensorId: '',
    nodeId: '',
    nodeType: '',
    cdtecDeviceId: '',
    nodeChip: '',
  });

  const [coordinates, setCoordinates] = useState<{ lng: number, lat: number }[] | null>(null);
  const [files, setFiles] = useState<Array<{ id: string, file: File }>>([]);

  useEffect(() => {
    if (selectedFarm?.name) {
      setDeviceInfo((prevInfo) => ({
        ...prevInfo,
        farm: selectedFarm.name,
      }));
    }
  }, [selectedFarm, isModalOpen]);

  const { data: dispatchGuideData } = useFetchApi<GetDispatchGuidesByAccountIdResponse>({
    modelEndpoint: 'accountDetail',
    modelName: 'dispatchGuides',
    id: selectedAccount?.id ?? '',
  });

  const handleValueChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    source: string,
  ) => {
    const { value } = e.target;
    setDeviceInfo((prevState) => ({ ...prevState, [source]: value }));
    if (source === 'deviceType' || source === 'farm') {
      setError((prevState) => ({
        ...prevState,
        [source]: '',
      }));
    }
  };

  const handleStep = (source: 'firstStep' | 'secondStep' | 'thirdStep') => {
    if (source === 'firstStep') {
      setModalOpen(true);
      setSteps({
        secondStep: false,
        thirdStep: false,
      });
    }

    if (source === 'secondStep') {
      if ((!deviceInfo.deviceType || deviceInfo.deviceType === '')) {
        setError((prevState) => ({
          ...prevState,
          deviceType: 'Debes seleccionar un tipo de equipo para avanzar',
        }));
        return;
      }

      if ((!deviceInfo.farm || deviceInfo.farm === '')) {
        setError((prevState) => ({
          ...prevState,
          farm: 'Debes seleccionar un campo para avanzar',
        }));
        return;
      }

      if (deviceInfo.deviceType === 'sensor' && !allFarmDevices.some((device) => device.deviceType === 'node')) {
        enqueueSnackbar('Primero debes agregar un nodo para poder asociar un sensor', { variant: 'error' });
        return;
      }

      setModalOpen(false);
      setSteps({
        secondStep: true,
        thirdStep: false,
      });
    }

    if (source === 'thirdStep') {
      // General
      if (deviceInfo.cdtecDeviceId === '' && !deviceInfo.dispatchGuideId) {
        setError((prevState) => ({
          ...prevState,
          cdtecDeviceId: 'Debes proporcionar un ID de nodo para avanzar',
        }));
        return;
      }
      // isSensor
      if (deviceInfo.deviceType === 'sensor') {
        if (!deviceInfo.dispatchGuideId) {
          if (deviceInfo.nodeId === '') {
            setError((prevState) => ({
              ...prevState,
              sensorId: 'Debes seleccionar un nodo para avanzar',
            }));
            return;
          }
          if (deviceInfo.sensorType === '') {
            setError((prevState) => ({
              ...prevState,
              sensorId: 'Debes seleccionar un tipo de sensor para avanzar',
            }));
            return;
          }
        } else if (deviceInfo.sensorId === '') {
          setError((prevState) => ({
            ...prevState,
            sensorId: 'Debes seleccionar sensor para avanzar',
          }));
          return;
        }
      } else {
        // is Node
        if (deviceInfo.dispatchGuideId) {
          if (!deviceInfo.nodeId) {
            setError((prevState) => ({
              ...prevState,
              nodeType: 'Debes seleccionar un nodo para avanzar',
            }));
            return;
          }
        } else if (deviceInfo.nodeType === '') {
          setError((prevState) => ({
            ...prevState,
            nodeType: 'Debes seleccionar un tipo de nodo para avanzar',
          }));
          return;
        }

        if (deviceInfo.isGatewayNode && !deviceInfo.nodeChip) {
          // isNode Gateway
          setError((prevState) => ({
            ...prevState,
            nodeChip: 'Si el nodo es Gateway, campo Chip no puede estar vacío',
          }));
          return;
        }
      }

      setSteps({
        secondStep: false,
        thirdStep: true,
      });
    }
  };

  const handleCloseAndResetData = () => {
    setError({
      deviceType: '',
      farm: '',
      sensorType: '',
      sensorId: '',
      nodeId: '',
      nodeType: '',
      cdtecDeviceId: '',
      nodeChip: '',
    });
    setDeviceInfo({ ...initialDeviceInfoState });
    setSteps({
      secondStep: false,
      thirdStep: false,
    });
    setCoordinates(null);
    setModalOpen(false);
    setFiles([]);
  };

  const insertFunction = async () => {
    // eslint-disable-next-line prefer-const
    setButtonDisabled(true);
    const dataToSend: { [key: string]: number | string | boolean } = {};
    Object.entries(deviceInfo).forEach(([key, value]) => {
      if (key !== 'farm' && value !== '') {
        if (deviceInfo.deviceType === 'sensor') {
          if (deviceInfo.dispatchGuideId) {
            if (keysPostSensorDispatchGuideSchema
              .includes(key as keyof PostSensorDispatchGuideSchema)) {
              dataToSend[key] = value;
            }
          } else if (keysPostSensorSchema.includes(key as keyof PostSensorSchema)) {
            dataToSend[key] = value;
          }
        } else if (deviceInfo.deviceType === 'node') {
          if (deviceInfo.dispatchGuideId) {
            if (keysPostNodeDispatchGuideSchema
              .includes(key as keyof PostNodeDispatchGuideSchema)) {
              dataToSend[key] = value;
            }
          } else if (keysPostNodeSchema.includes(key as keyof PostNodeSchema)) {
            dataToSend[key] = value;
          }
        }
      }
    });

    if (!coordinates) {
      enqueueSnackbar('Debes elegir donde colocar el sensor/nodo', { variant: 'error' });
      return;
    }

    try {
      const formData = new FormData();
      files.forEach((singleFile) => {
        formData.append('files', singleFile.file);
      });
      formData.append('data', JSON.stringify({
        ...dataToSend,
        farmId: selectedFarm?.id ?? '',
        coordinates: coordinates[0],
      }));

      // apiClient not was working correclty on creating multiple images
      const { data } = await axios.post('/api/devices', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      const newDevice = data.device;

      handleCloseAndResetData();
      dispatch(setAllFarmDevices([...allFarmDevices, newDevice] as DeviceSchema[]));
      dispatch(setFarmDevice(newDevice?.id as string));
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { response: fetchError } = err as AxiosError;
      if (fetchError?.data) {
        enqueueSnackbar((fetchError.data as { message: string }).message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    } finally {
      setButtonDisabled(false);
    }
  };

  const getRelevantDevices = () => dispatchGuideData?.dispatchGuides
    ?.find((dGuide) => dGuide.id === deviceInfo.dispatchGuideId)?.devices ?? [];

  return (
    <CustomModal
      open={isModalOpen || steps.secondStep || steps.thirdStep}
      handleClose={handleCloseAndResetData}
      cardClassName={steps.thirdStep ? classes.cardModalContainer : undefined}
    >
      <>
        {isModalOpen
          && <Box display='flex' flexDirection='column' flex='1'>
            <Box className={classes.uploadAddDevicesHeader}>
              <Typography
                variant="h3"
                color="textPrimary"
                className={classes.uploadAddDevicesHeaderTitle}
              >
                <span className={classes.activePageUnderlined}>
                  Agregar equipo
                </span>
              </Typography>
              <IconButton onClick={handleCloseAndResetData}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Tipo (*)'
                variant='standard'
                select
                fullWidth
                error={error.deviceType !== ''}
                helperText={error.deviceType !== '' && error.deviceType}
                className={classes.singleLeftTextfield}
                value={deviceInfo.deviceType}
                onChange={(e) => handleValueChange(e, 'deviceType')}
              >
                <MenuItem value={'node'}>
                  Nodo
                </MenuItem>
                <MenuItem value={'sensor'}>
                  Sensor
                </MenuItem>
              </TextField>
              <TextField
                label='Campo'
                variant='standard'
                // select
                fullWidth
                error={error.farm !== ''}
                helperText={error.farm !== '' && error.farm}
                value={deviceInfo.farm}
                disabled
              // onChange={(e) => handleValueChange(e, 'farm')}
              />
              {/* CAMBIAR DESPOUES - REVISAR. Se agrega solo al que ya elegimos,
                o podemos agregar a cualquiera */}
              {/* {farmsOptions
                  ? farmsOptions.map((farm) => (
                    <MenuItem key={farm.id} value={farm.id}>
                      {farm.name}
                    </MenuItem>))
                  : <MenuItem disabled> No existen campos</MenuItem>} */}
              {/* </TextField> */}
            </Box>
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Sector (opcional)'
                variant='standard'
                fullWidth
                select
                className={classes.singleLeftTextfield}
                value={deviceInfo.sectorId}
                onChange={(e) => handleValueChange(e, 'sectorId')}
              >
                {/* CAMBIAR DESPOUES - REVISAR */}
                {(selectedFarm?.sectors && selectedFarm.sectors.length > 0)
                  ? selectedFarm.sectors.map((sector) => (
                    <MenuItem key={sector.id} value={sector.id}>
                      {sector.name}
                    </MenuItem>))
                  : <MenuItem disabled> No existen sectores</MenuItem>}
              </TextField>
              <TextField
                label='Subsector (opcional)'
                select
                variant='standard'
                fullWidth
                disabled={!deviceInfo.sectorId}
                value={deviceInfo.subSector}
                onChange={(e) => handleValueChange(e, 'subSector')}
              >
                {(selectedFarm?.sectors && selectedFarm.sectors.length > 0)
                  ? selectedFarm.sectors
                    .find((sec) => sec.id === deviceInfo.sectorId)
                    ?.subSectors?.map((subSec) => (
                      <MenuItem key={subSec.subSectorId} value={subSec.subSectorId}>
                        {subSec.name}
                      </MenuItem>))
                  : <MenuItem disabled> No existen subsectores</MenuItem>}
              </TextField>
            </Box>
            <Box className={classes.singleTextFieldsContainer}>
              <TextField
                label='Guía de despacho (opcional)'
                select
                variant='standard'
                fullWidth
                value={deviceInfo.dispatchGuideId}
                onChange={(e) => handleValueChange(e, 'dispatchGuideId')}
              >
                {(dispatchGuideData?.dispatchGuides
                  && dispatchGuideData?.dispatchGuides?.length > 0)
                  ? dispatchGuideData.dispatchGuides.map((dGuide) => (
                    <MenuItem key={dGuide.id} value={dGuide.id}>
                      {dGuide.name}
                    </MenuItem>))
                  : <MenuItem disabled> No existen guías de despacho</MenuItem>}
              </TextField>
            </Box>
            <Box className={classes.buttonContainer}>
              <Box className={classes.singleButtonContainer} marginRight='20px'>
                <CustomButton buttonText='cancelar' onClick={handleCloseAndResetData} />
              </Box>
              <Box className={classes.singleButtonContainer}>
                <CustomButton buttonText='siguiente' onClick={() => handleStep('secondStep')} />
              </Box>
            </Box>
          </Box>}
        {steps.secondStep && <AddDevicesFlowSecondStep
          deviceInfo={deviceInfo}
          setDeviceInfo={setDeviceInfo}
          devicesOptions={deviceInfo.dispatchGuideId ? getRelevantDevices() : null}
          isSensor={deviceInfo.deviceType === 'sensor'}
          hasDispatchGuide={Boolean(deviceInfo.dispatchGuideId && deviceInfo.dispatchGuideId !== '')}
          handleCloseModal={handleCloseAndResetData}
          handleStep={handleStep}
          error={error}
          setError={setError}
          files={files}
          setFiles={setFiles}
        />}
        {steps.thirdStep && <AddDevicesFlowThirdStep
          title={deviceInfo.deviceType === 'sensor' ? 'Agregar sensor' : 'Agregar nodo'}
          detailedInfoTitle={deviceInfo.deviceType === 'sensor' ? 'Info sensor' : 'Info nodo'}
          detailedInfo={{
            Campo: deviceInfo.farm,
            Sector: (deviceInfo?.sectorId && deviceInfo.sectorId !== '')
              ? selectedFarm?.sectors?.find((sector) => sector.id === deviceInfo.sectorId)
                ?.wiseconnId ?? '-'
              : '-',
            Subsector: (deviceInfo?.subSector && deviceInfo.subSector !== '') ? deviceInfo.subSector : '-',
          }}
          handleCloseModal={handleCloseAndResetData}
          handleStep={handleStep}
          insertFunction={insertFunction}
          setCoordinates={setCoordinates}
          buttonDisabled={buttonDisabled}
        />}
      </>
    </CustomModal>
  );
};

export default AddDevicesFlow;
