import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  Slash as SlashIcon,
} from 'react-feather';
import useStyles from './styles';

type TableHeaders = {
  label: string,
  key: string,
  className?: string,
};

type TableRows = {
  className?: string,
  [key: string]: string | JSX.Element | number | undefined,
};

type TableRowsInfo = {
  values: TableRows[];
  count: number;
};

type PaginationParameters = { page: number, rowsPerPage: number };

const CustomTable = ({
  headers, rows, paginationParameters, setPaginationParameters,
}: {
  headers: TableHeaders[],
  rows: TableRowsInfo,
  paginationParameters?: PaginationParameters,
  setPaginationParameters?: React.Dispatch<React.SetStateAction<PaginationParameters>>,
}) => {
  const classes = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaginationParameters?.((prevState) => ({ ...prevState, page: newPage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParameters?.({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      {rows.count > 0 ? (
        <TableContainer className={classes.extraPadding}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header.label} className={`${classes.tableHeaderText} ${header.className ?? ''}`.trim()}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.values.map((row) => (
                <TableRow key={row.id as string}>
                  {headers.map((header) => (
                    <TableCell key={header.key} className={row.className}>
                      {row[header.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className={classes.noDataDiv}>
          <SvgIcon className={classes.iconContainer}>
            <SlashIcon />
          </SvgIcon>
          <Box>
            <Typography
              variant="h1"
            >
              No hay datos
            </Typography>
          </Box>
        </Box>
      )}

      {paginationParameters && setPaginationParameters && rows.count > 0 && (
        <TablePagination
          component="div"
          count={rows.count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={paginationParameters.page}
          rowsPerPage={paginationParameters.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={'Filas por página'}
          className={classes.extraPadding}
        />
      )}
    </>
  );
};

export default CustomTable;
