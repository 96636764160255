import { DeviceSchema } from '../requests/api/apiTypes';
import { SensorNode } from '../types';

export const SET_FARM_SECTOR = '@account-farm/set-farm-sector';
export const SET_FARM_DEVICE = '@account-farm/set-farm-device';
export const SET_ALL_FARM_DEVICES = '@account-farm/set-all-farm-devices';
export const SET_SENSOR_NODE_FILTER = '@account-farm/set-node-sensor-filter';

export const setFarmSector = (sectorData: string) => ({
  type: SET_FARM_SECTOR,
  payload: sectorData,
});

export const setFarmDevice = (deviceData: string) => ({
  type: SET_FARM_DEVICE,
  payload: deviceData,
});

export const setAllFarmDevices = (devicesData: DeviceSchema[]) => ({
  type: SET_ALL_FARM_DEVICES,
  payload: [...devicesData],
});

export const setSensorNodeFilter = (sensorNodeInfo: SensorNode) => ({
  type: SET_SENSOR_NODE_FILTER,
  payload: sensorNodeInfo,
});
