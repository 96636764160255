import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Redirect, useHistory, useParams } from 'react-router';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSnackbar } from 'notistack';

import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import Page from '../../../../components/Layout/Page';
import CustomHeader from '../../../../components/General/CustomHeader';
import CustomButton from '../../../../components/General/CustomButton';
import {
  SectorDetailSchema, VarietySchema, UpdateSectorSchema, CultivationCyclePopulatedSchema,
} from '../../../../requests/api/apiTypes';
import apiClient from '../../../../requests/api/apiClient';
import {
  RouteParams,
  REQUIRED_UPDATE_SECTOR_FIELDS,
  OPTIONAL_UPDATE_SECTOR_FIELDS,
  REQUIRED_UPDATE_SECTOR_EQ,
} from '../types';
import { FetchError } from '../../../types';
import { SOIL_TYPES } from './types';

import CultivationCyclesList from './component/CultivationCyclesList';
import PhenologicalStatesList from './component/PhenologicalStatesList';
import CustomConfirmationModal from '../../../../components/General/CustomConfirmationModal';
import { ReduxState } from '../../../../types';
import { setAccountFarm } from '../../../../actions/accountFarmAction';
import { setFarmSector } from '../../../../actions/farmSectorActions';

// TO DO: separar los tabs
const SectorDetail = () => {
  const classes = useStyles();
  const { id: sectorId } = useParams<RouteParams>();

  const [value, setValue] = useState('2');
  const [notFound, setNotFound] = useState(false);
  const [sectorInfo, setSectorInfo] = useState<SectorDetailSchema | undefined>(undefined);

  const [varietiesList, setVarietiesList] = useState<VarietySchema[] | undefined>(undefined);
  // Estas 2 deberian irse al tab correspondiente
  const [selectedSpecies, setSelectedSpecies] = useState<string>('');
  const [selectedVarietyId, setSelectedVarietyId] = useState<string | undefined>('');

  const [cultivationCycles, setCultivationCycles] = useState<CultivationCyclePopulatedSchema[]>([]);
  const [brotherSectors, setBrotherSectors] = useState<SectorDetailSchema[]>([]);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.farmSector);
  const dispatch = useDispatch();

  const handleDeleteSector = async () => {
    try {
      await apiClient.sector.sectorDelete(sectorId);
      enqueueSnackbar('Sector eliminado con éxito', { variant: 'success' });
      setDeleteModalOpen(false);
      if (selectedFarm) {
        // Create updated farm object with filtered sectors
        const updatedFarm = {
          ...selectedFarm,
          sectors: selectedFarm.sectors?.filter(
            (sector) => sector.id !== sectorId,
          ) || [],
        };

        // Update Redux store with new farm data
        dispatch(setAccountFarm(updatedFarm));

        // Clear selected sector if it was the one deleted
        if (selectedSector === sectorId) {
          dispatch(setFarmSector(''));
        }
      }
      history.push('/layout');
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    const getSectorInformation = async () => {
      try {
        const { data } = await apiClient.sector.sectorDetail(sectorId);
        const sectorData = data.sector;
        setSectorInfo(sectorData);
        // Get brother sectors using farmId
        const { data: farmSectorsData } = await apiClient.sectors.sectorsDetail(sectorData?.farm ?? '');
        const farmSectors = farmSectorsData.sectors;
        // Set brother sectors to all except the current sector
        setBrotherSectors(farmSectors?.filter((sector) => sector.id !== sectorId) ?? []);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
        setNotFound(true);
      }
    };

    const getVarietiesList = async () => {
      try {
        const varietiesResponse = await apiClient.varieties.varietiesList();
        const varietiesInformation = varietiesResponse.data?.varieties?.map((variety) => ({
          id: variety.id,
          name: variety.name,
          species: variety.species,
          phenologicalStates: variety.phenologicalStates,
        })) || [];

        setVarietiesList(varietiesInformation);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };

    const getCultivationCycles = async () => {
      try {
        const { data } = await apiClient.cultivationCycleSector.cultivationCycleSectorDetail(
          sectorId,
        );
        const cultivationCyclesData = data.cultivationCycles;
        setCultivationCycles(cultivationCyclesData);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };

    getSectorInformation();
    getVarietiesList();
    getCultivationCycles();
  }, []);

  useEffect(() => {
    if (sectorInfo && varietiesList) {
      const selectedVariety = varietiesList.find((variety) => variety.id === sectorInfo.variety);
      if (selectedVariety) {
        setSelectedSpecies(selectedVariety.species);
        setSelectedVarietyId(selectedVariety.id);
      }
    }
  }, [sectorInfo, varietiesList]);

  const filteredVarieties = varietiesList?.filter(
    (variety) => variety.species === selectedSpecies,
  ) || [];

  const handleFieldChange = (field: keyof SectorDetailSchema, newValue: string | number) => {
    setSectorInfo((prev) => {
      if (!prev) return prev;

      return {
        ...prev,
        [field]: newValue,
      };
    });
  };

  const handleVarietyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    setSelectedVarietyId(selectedId);
    handleFieldChange('variety', selectedId);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleUpdateSectorInformation = async () => {
    if (!sectorInfo) {
      enqueueSnackbar('La información del sector no está disponible.', { variant: 'error' });
      return;
    }

    const hasMissingFields = REQUIRED_UPDATE_SECTOR_FIELDS.some((field) => {
      if (!sectorInfo[field as keyof SectorDetailSchema]) {
        enqueueSnackbar(`El campo ${REQUIRED_UPDATE_SECTOR_EQ[field]} debe ser completado`, { variant: 'error' });
        return true;
      }
      return false;
    });

    if (hasMissingFields) return;

    try {
      const allowedFields = [...REQUIRED_UPDATE_SECTOR_FIELDS, ...OPTIONAL_UPDATE_SECTOR_FIELDS];
      const requestBody = Object.fromEntries(
        Object.entries(sectorInfo).filter(([key]) => allowedFields.includes(key)),
      ) as UpdateSectorSchema;
      const response = await apiClient.sector.sectorUpdate(sectorId, { ...requestBody });
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const tittle = `Layout/${sectorInfo?.name} - Sector ${sectorInfo?.wiseconnId}`;

  if (notFound) {
    return <Redirect to="/layout" />;
  }

  return (
    <Page
      className={classes.root}
      title='Layout'
    >
      <Container maxWidth={false}>
        <CustomHeader title={tittle} />
        <Box height='100%' display='block' pt={3}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 0 }}>
              <TabList onChange={handleChange} aria-label="sector tabs">
                <Tab label="Dashboards" value="1" className={classes.tabLabel}/>
                <Tab label="Información" value="2" className={classes.tabLabel}/>
                <Tab label="Estados fenológicos" value="3" className={classes.tabLabel}/>
                <Tab label="Alertas" value="4" className={classes.tabLabel}/>
                <Tab label="Variables derivadas" value="5" className={classes.tabLabel}/>
              </TabList>
            </Box>
              <TabPanel value="1"></TabPanel>
              <TabPanel value="2">
                <Box mt={1}>
                  <Card>
                    <CardContent>
                      <Typography variant="h4" color="primary" gutterBottom>
                        General
                      </Typography>
                      <Divider className={classes.divider} />
                      <Grid container spacing={2}>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Identificación cliente"
                            value={sectorInfo?.name || ''}
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                            fullWidth
                            variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                          label="Superficie teórica"
                          value={sectorInfo?.theoreticalSurface || ''}
                          onChange={(e) => handleFieldChange('theoreticalSurface', e.target.value)}
                          fullWidth variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                          label="Wiseconn ID"
                          value={sectorInfo?.wiseconnId || ''}
                          onChange={(e) => handleFieldChange('wiseconnId', e.target.value)}
                          fullWidth variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                          label="Equipo de riego"
                          value={sectorInfo?.sectorDevice || ''}
                          onChange={(e) => handleFieldChange('sectorDevice', e.target.value)}
                          fullWidth variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Tipo de riego"
                            value={sectorInfo?.typeOfIrrigation || ''}
                            onChange={(e) => handleFieldChange('typeOfIrrigation', e.target.value)}
                            fullWidth
                            select
                            variant="standard"
                          >
                            <MenuItem value="Gotero">Gotero</MenuItem>
                            <MenuItem value="Aspersor">Aspersor</MenuItem>
                            <MenuItem value="Otro">Otro</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Tipo de suelo"
                            value={sectorInfo?.typeOfSoil || ''}
                            onChange={(e) => handleFieldChange('typeOfSoil', e.target.value)}
                            fullWidth
                            select
                            variant="standard"
                            >
                              {SOIL_TYPES.map((soilType) => (
                                <MenuItem key={soilType} value={soilType}>
                                  {soilType}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Especie"
                            value={selectedSpecies}
                            onChange={(e) => {
                              setSelectedSpecies(e.target.value);
                              setSelectedVarietyId('');
                            }}
                            fullWidth
                            select
                            variant="standard"
                            >
                              {[...new Set(varietiesList?.map((variety) => variety.species))].map(
                                (species) => (
                                  <MenuItem key={species} value={species}>
                                    {species}
                                  </MenuItem>

                                ),
                              )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Variedad"
                            value={selectedVarietyId}
                            onChange={handleVarietyChange}
                            fullWidth
                            select
                            variant="standard"
                            >
                              {filteredVarieties.map((variety) => (
                                <MenuItem key={variety.id} value={variety.id}>
                                  {variety.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Año de plantación"
                            value={sectorInfo?.plantingYear || ''}
                            onChange={(e) => handleFieldChange('plantingYear', parseInt(e.target.value, 10))}
                            fullWidth
                            variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Caudal del emisor"
                            value={sectorInfo?.emitterFlowRate || ''}
                            onChange={(e) => handleFieldChange('emitterFlowRate', e.target.value)}
                            fullWidth
                            variant="standard"/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Distancia entre emisores (mts)"
                            value={sectorInfo?.transmittersDistance || ''}
                            onChange={(e) => handleFieldChange('transmittersDistance', parseInt(e.target.value, 10))}
                            fullWidth
                            variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Marco de plantación"
                            value={sectorInfo?.plantingFrame || ''}
                            onChange={(e) => handleFieldChange('plantingFrame', e.target.value)}
                            fullWidth
                            variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Encargado"
                            value={sectorInfo?.managerName || ''}
                            onChange={(e) => handleFieldChange('managerName', e.target.value)}
                            fullWidth
                            variant="standard"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Sector padre"
                            value={sectorInfo?.parentSector || '1'}
                            onChange={(e) => handleFieldChange('parentSector', e.target.value)}
                            fullWidth
                            select
                            variant="standard"
                          >
                            <MenuItem value="1">Ninguno</MenuItem>
                            {brotherSectors.map((sector) => (
                              <MenuItem key={sector.id} value={sector.id}>
                                {sector.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Número de sector"
                            value={sectorInfo?.sectorNumber || ''}
                            onChange={(e) => handleFieldChange('sectorNumber', e.target.value)}
                            fullWidth
                            variant="standard"/>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
                <CultivationCyclesList
                  cultivationCycles={
                    cultivationCycles.map((cycle) => ({
                      name: cycle.name,
                      id: cycle.id,
                      startDate: cycle.startDate,
                      endDate: cycle.endDate,
                      varietyName: cycle.variety.name,
                      varietySpecies: cycle.variety.species,
                    }))
                  }
                  sectorId={sectorId}
                  varietiesList={varietiesList ?? []}
                  setCultivationCyclesFunction={setCultivationCycles}
                  />
                <Grid container justifyContent="flex-end" spacing={3}>
                  <Grid item xs={4} md={2}>
                    <CustomButton
                      buttonText='Guardar cambios'
                      color='blue'
                      onClick={handleUpdateSectorInformation}
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <CustomButton
                      buttonText='Eliminar Sector'
                      color='red'
                      onClick={() => setDeleteModalOpen(true)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3">
                <PhenologicalStatesList
                  cultivationCycles={cultivationCycles.map((cycle) => ({
                    id: cycle.id,
                    name: cycle.name,
                    phenologicalStates: cycle.phenologicalStates,
                  }))}
                  setCultivationCyclesFunction={setCultivationCycles}
                />
              </TabPanel>
              <TabPanel value="4"></TabPanel>
              <TabPanel value="5"></TabPanel>
          </TabContext>
        </Box>
      </Container>
      <CustomConfirmationModal
        open={deleteModalOpen}
        title="Eliminar Sector"
        message="¿Estás seguro de que quieres eliminar este sector?"
        onConfirm={handleDeleteSector}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButtonColor="delete"
        confirmText='Eliminar'
      />
    </Page>
  );
};

export default SectorDetail;
