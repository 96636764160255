import React, { useState } from 'react';
import {
  Box,
  Container,
  IconButton,
  Typography,
} from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../components/General/CustomHeader';
import WorkOrderList from './component/WorkOrderList';
import WorkOrderStatusIndicator from './component/WorkOrderStatusIndicator';
import CustomScrollableTable from './component/CustomScrollableTable';
import useFetchApi from '../../../hooks/useFetchApi';
import { GetWorkOrdersPerPageResponse } from '../../../requests/api/apiTypes';
import CustomModal from '../../../components/General/CustomModal';
import CustomDispatchGuideContent from './component/CustomDispatchGuideContent';
import { formatLocaleDate } from '../../../utils/formatDate';
import { ReduxState } from '../../../types';

type DispatchGuideInformation = {
  id: string,
  date: string,
  type: string,
  numOfDevices: number,
  seeMore: JSX.Element,
};

type DevicesObject = {
  [codProd: string]: {
    quantity: number,
    id: string;
    name: string;
    model?: string;
    group?: string;
    subGroup?: string;
    deleted: boolean;
  }
};

const DEVICE_LIST_UI = [
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'Código', key: 'codProd',
  },
  {
    label: 'Grupo', key: 'group',
  },
  {
    label: 'Subgrupo', key: 'subGroup',
  },
  {
    label: 'Cantidad', key: 'quantity',
  },
];

const DISPATCH_GUIDE_LIST_UI = [
  {
    label: 'ID', key: 'id',
  },
  {
    label: 'Fecha', key: 'date',
  },
  {
    label: 'Tipo', key: 'type',
  },
  {
    label: 'Ver guía', key: 'seeMore',
  },
];

const DISPATCH_GUIDE_TYPES = {
  s: 'Salida',
  e: 'Entrada',
};

const oneButtonAction = (
  dGuideId: string,
  setShowDispatchGuideModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box display='flex' alignItems='center' justifyContent='center'>
  <IconButton
    onClick={() => setShowDispatchGuideModal({ id: dGuideId, open: true })}>
    <RemoveRedEye />
  </IconButton>
</Box>);

const WorkOrderListView = () => {
  const classes = useStyles();
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<string | null>(null);
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showDispatchGuideModal, setShowDispatchGuideModal] = useState({ id: '', open: false });

  const { data } = useFetchApi<GetWorkOrdersPerPageResponse>({
    modelName: 'workOrders',
    modelEndpoint: 'workOrdersList',
    inputData: {
      searchValue,
      accountId: selectedAccount?.id as string,
    },
    dependencies: [fetchAgain, searchValue],
  });

  const workOrderInfo = data?.workOrders
    ?.find((order) => order.id === selectedWorkOrder);

  const dispatchGuidesInfo: DispatchGuideInformation[] = [];
  const devicesObj: DevicesObject = {};

  workOrderInfo?.dispatchGuides?.forEach((dGuide) => {
    dispatchGuidesInfo.push({
      id: dGuide.name ?? '',
      date: dGuide.dGuideSoftLandCreationDate ? formatLocaleDate(dGuide.dGuideSoftLandCreationDate) : '',
      type: DISPATCH_GUIDE_TYPES[dGuide.type as 's' | 'e'],
      numOfDevices: dGuide.devices ? dGuide.devices.length : 0,
      seeMore: oneButtonAction(dGuide.id as string, setShowDispatchGuideModal),
    });

    if (dGuide?.devices) {
      dGuide?.devices.forEach((elem) => {
        const { deviceModel } = elem;
        if (deviceModel) {
          // Device models from dispatch guides must ALWAYS have codProd
          const { codProd, ...rest } = deviceModel;
          if (devicesObj[codProd as string]) {
            devicesObj[codProd as string].quantity += 1;
          } else {
            devicesObj[codProd as string] = { ...rest, quantity: 1 };
          }
        }
      });
    }
  });

  const devicesToShow = Object.entries(devicesObj).map(([key, value]) => ({
    name: value.name,
    codProd: key,
    model: value?.model ?? '-',
    group: value.group,
    subGroup: value.subGroup,
    quantity: value.quantity,
  }));

  const getRelevantDispatchGuide = () => {
    const dGuide = workOrderInfo?.dispatchGuides
      ?.find((elem) => elem.id === showDispatchGuideModal.id) ?? {};

    return {
      id: dGuide.name ?? '-',
      dGuideSoftLandCreationDate: dGuide.dGuideSoftLandCreationDate ? formatLocaleDate(dGuide.dGuideSoftLandCreationDate) : '-',
      type: dGuide.type ?? '-',
      dispatchGuideDetailInfo: dGuide?.devices?.map((device) => ({
        id: device.deviceModel?.id ?? '-',
        name: device.deviceModel?.name ?? '-',
        codProd: device.deviceModel?.codProd ?? '-',
        serie: device?.serie ?? '-',
        model: device.deviceModel?.model ?? '-',
        group: device.deviceModel?.group ?? '-',
        subGroup: device.deviceModel?.subGroup ?? '-',
      })) ?? [],
    };
  };

  const dispatchGuideUi = DISPATCH_GUIDE_LIST_UI.map((dGuideUI) => {
    if (dGuideUI.key === 'seeMore') {
      return {
        ...dGuideUI,
        className: classes.dispatchGuideTableHeader,
      };
    }
    return dGuideUI;
  });

  return (
    <Page
      className={classes.root}
      title='Órdenes de trabajo'
    >
      <Container maxWidth={false} className={classes.containerContainer}>
        <CustomHeader title='Órdenes de trabajo' />
        <Box height='100%' display='flex' pt={3}>
          <WorkOrderList
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            workOrders={data?.workOrders ?? []}
            selectedWorkOrder={selectedWorkOrder}
            setSelectedWorkOrder={setSelectedWorkOrder}
            setFetchAgain={setFetchAgain}
          />
          <Box className={classes.mainAreaContainer}>
            {data?.workOrders && data.workOrders.length > 0 && selectedWorkOrder && <>
              <WorkOrderStatusIndicator
                status={data?.workOrders.find((order) => selectedWorkOrder === order.id)?.status as 'n' | 'p' | 'a' | 'c' | undefined}
              />
              <CustomScrollableTable
                containerClassName={classes.requestedDevicesContainer}
                title='Equipos solicitados'
                headers={DEVICE_LIST_UI}
                rows={{ values: devicesToShow, count: devicesToShow.length }}
                noElementsMessage='No existen equipos asociados'
              />
              <CustomScrollableTable
                containerClassName={classes.dispatchGuideContainer}
                title='Guías de despacho'
                headers={dispatchGuideUi}
                rows={{ values: dispatchGuidesInfo, count: dispatchGuidesInfo.length }}
                noElementsMessage='No existen guías de despacho disponibles'
              />
            </>}
            {data?.workOrders && data.workOrders.length > 0 && !selectedWorkOrder && <Typography variant='body1'>
              Selecciona una órden de trabajo
            </Typography>}
          </Box>
        </Box>
      </Container>
      <CustomModal
        open={showDispatchGuideModal.open}
        handleClose={() => setShowDispatchGuideModal({ id: '', open: false })}
      >
        <CustomDispatchGuideContent handleCloseModal={() => setShowDispatchGuideModal({ id: '', open: false })} {...getRelevantDispatchGuide()} />
      </CustomModal>
    </Page>
  );
};

export default WorkOrderListView;
