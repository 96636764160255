import {
  Typography,
  IconButton,
  Divider,
  Box,
} from '@mui/material';

import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../../components/General/CustomButton';
import useStyles from '../../styles';
import GoogleMaps from '../GoogleMaps';
import { ReduxState } from '../../../../../types';
import { filteredDevices } from '../../helpers';

const AddDevicesFlowThirdStep = ({
  // setDeviceInfo,
  title,
  detailedInfoTitle,
  detailedInfo,
  handleCloseModal,
  handleStep,
  insertFunction,
  setCoordinates,
  buttonDisabled,
}: {
  title: string,
  detailedInfoTitle: string,
  detailedInfo: {
    [key: string]: string | number,
  },
  handleCloseModal: VoidFunction,
  handleStep: (step: 'firstStep' | 'secondStep' | 'thirdStep') => void,
  insertFunction: VoidFunction,
  setCoordinates: React.Dispatch<React.SetStateAction<{ lng: number, lat: number }[] | null>>,
  buttonDisabled: boolean,
}) => {
  const classes = useStyles();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { allFarmDevices, sensorNodeFilter } = useSelector((state: ReduxState) => state.farmSector);
  const parsedInformation = Object.entries(detailedInfo).map(([label, value]) => ({
    label,
    value,
  }));

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    })) : null;

  return (
    <Box display='flex' flexDirection='column' flex='1'>
      <Box className={classes.uploadKMZModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.uploadKMZModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {title}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.addDevicesFlowListTileAndTextContainer}>
        <Box className={classes.addDevicesFlowListTileContainer}>
          <Typography
            variant="h4"
          >
            {detailedInfoTitle}
          </Typography>
          {parsedInformation.map((info) => <Box key={info.label}>
            <Typography
              component='span'
              fontSize='12px'

            >
              {info.label}{': '}
            </Typography>
            <Typography
              component='span'
              fontSize='12px'
              fontWeight='bold'
            >
              {info.value}
            </Typography>
          </Box>)}
        </Box>
        <Box className={classes.typographyNodeTitleContainer}>
          <Typography
            variant='body2'
            className={classes.typographyNodeTitle}
          >
            Haz click en el mapa donde quieras poner el nodo. Una vez que hayas elegido el destino
            final de este, presiona agregar para finalizar el proceso.
          </Typography>
        </Box>

      </Box>
      <Divider className={classes.divider} />
      <Box border='1px solid black' flex='1'>
        <GoogleMaps
          farm={detailedInfo.Sector === '-' ? {
            id: selectedFarm?.id ?? '',
            coordinates: farmCoordinates,
            zoom: selectedFarm?.zoom ?? 18,
          } : {
            id: '',
            coordinates: [],
            zoom: selectedFarm?.zoom ?? 18,
          }}
          lat={selectedFarm?.center.lat ?? ''}
          lng={selectedFarm?.center.lng ?? ''}
          drawable='marker'
          polygons={selectedFarm?.sectors?.filter(
            (sector) => sector.wiseconnId === detailedInfo.Sector,
          ) ?? []}
          markers={filteredDevices(allFarmDevices, sensorNodeFilter)}
          setCoordinates={setCoordinates}
        />
      </Box>
      <Box className={classes.addPolygonButtonContainer}>
        <Box className={classes.singleButtonContainer} marginRight='20px'>
          <CustomButton buttonText='anterior' onClick={() => handleStep('secondStep')} />
        </Box>
        <Box className={classes.singleButtonContainer}>
          <CustomButton buttonText='Agregar' onClick={insertFunction} color='lightBlue' disabled={buttonDisabled} />
        </Box>
      </Box>
    </Box>
  );
};

export default AddDevicesFlowThirdStep;
