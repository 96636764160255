import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

function CardHeader({ title, ActionButton }: {
  title: string,
  ActionButton?: JSX.Element;
}) {
  const parsedTitle = title.split('/').map((elem, index) => (
    <React.Fragment key={index}>
      {elem.trim()}
    </React.Fragment>
  ));

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={1}
      alignItems="center"
    >
      <Grid item>
        <Typography
          variant="h4"
          color="primary"
          gutterBottom
        >
          {parsedTitle}
        </Typography>
      </Grid>
      {ActionButton && (
        <Grid item xs={3} md={3}>
          {ActionButton}
        </Grid>
      )}
    </Grid>
  );
}

export default CardHeader;
