/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { SET_ACCOUNT_FARM, RESET_ACCOUNT_FARM, SET_ACCOUNT_FARMS_OPTIONS } from '../actions/accountFarmAction';
import { AccountFarmCustomAction, AccountFarmState } from '../types';
import { FarmSchema } from '../requests/api/apiTypes';

const initialState: AccountFarmState = {
  selectedFarm: null,
  farmsOptions: null,
};

const accountFarmReducer = (
  state = initialState,
  action: AccountFarmCustomAction,
): AccountFarmState => {
  switch (action.type) {
    case SET_ACCOUNT_FARM: {
      return produce(state, (draft: Draft<AccountFarmState>) => {
        draft.selectedFarm = action.payload ? { ...action.payload as FarmSchema } : null;
      });
    }

    case SET_ACCOUNT_FARMS_OPTIONS: {
      return produce(state, (draft: Draft<AccountFarmState>) => {
        draft.farmsOptions = action.payload as FarmSchema[];
      });
    }

    case RESET_ACCOUNT_FARM: {
      return produce(state, (draft: Draft<AccountFarmState>) => {
        draft.selectedFarm = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountFarmReducer;
