import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

import useStyles from '../styles';

function Header({ userToModify, loading, handleSubmit }: {
  userToModify: string | undefined,
  loading: boolean,
  handleSubmit: (userToModify: string | undefined) => void,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton onClick={() => history.goBack()} aria-label="go back">
              <ArrowBackIos />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography
              variant="h3"
              color="textPrimary"
            >
              Usuarios / {userToModify
              ? <span className={classes.activePageUnderlined}>Editar usuario</span>
              : <span className={classes.activePageUnderlined}>Crear usuario</span>}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          onClick={() => handleSubmit(userToModify)}
          variant='contained'
          className={classes.greenButton}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Confirmar'}
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  userToModify: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default Header;
