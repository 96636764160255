import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { THEMES } from '../../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  textColor: {
    color: theme.palette.text.tertiary,
  },
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.primary.main,
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {}),
  },
  toolbar: {
    minHeight: 64,
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '50px',
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popoverSettings: {
    width: 320,
    padding: theme.spacing(2),
  },
  icon: {
    filter: theme.settings.filter,
  },
}));

export default useStyles;
