/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { RESET_USER_ACCOUNT, SET_USER_ACCOUNT } from '../actions/userAccountAction';
import { UserAccountCustomAction, UserAccountState } from '../types';

const initialState: UserAccountState = {
  selectedAccount: JSON.parse(sessionStorage.getItem('selectedAccount') || 'null'),
};

const userAccountReducer = (
  state = initialState,
  action: UserAccountCustomAction,
): UserAccountState => {
  switch (action.type) {
    case SET_USER_ACCOUNT: {
      return produce(state, (draft: Draft<UserAccountState>) => {
        draft.selectedAccount = action.payload;
      });
    }

    case RESET_USER_ACCOUNT: {
      return produce(state, (draft: Draft<UserAccountState>) => {
        draft.selectedAccount = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default userAccountReducer;
