import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../../components/Layout/Page';
import useStyles, { linkStyleFormat } from './styles';
import { ReduxState } from '../../types';
import fallbackRoutes from '../../constants/fallbackRoutes';
import { logout } from '../../actions/accountActions';
import { persistorFlush } from '../../store';

function Unauthorized() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: ReduxState) => state.account.user);

  const fallBackRoute = user?.role ? fallbackRoutes[user.role] : '/login';

  const handleLogout = async () => {
    try {
      dispatch(logout());
      await persistorFlush();
      history.push('/');
    } catch (error) {
      enqueueSnackbar('No se pudo cerrar sesión.', {
        variant: 'error',
      });
    }
  };

  return (
    <Page
      className={classes.root}
      title={'Sin autorización'}
    >
      <Box className={classes.mainDiv}>
        {/* eslint-disable-next-line global-require */}
        <img src={require('../../assets/images/error_401.jpg')} width={800} />
        <Box
          maxWidth={800}
          mt={2}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            align='justify'
          >
            ¡Ups! No tienes permiso para acceder esta página. Por favor pide permiso al
            administrador de tu cuenta, clickea
            <Link
              to={fallBackRoute} style={linkStyleFormat}>aquí
            </Link>
            para ir a tu página principal, o clickea
            <span className={classes.linkStyleFormat} onClick={handleLogout}>
              aquí
            </span>para cambiar de usuario.
          </Typography>
        </Box>
      </Box>
    </Page>
  );
}

export default Unauthorized;
