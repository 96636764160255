import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles'; // Import the theme hook
import useStyles from './styles'; // Make sure to adjust the import path as needed

interface CustomConfirmationModalProps {
  open: boolean;
  title: string;
  message: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonColor?: 'delete' | 'confirm';
}

const CustomConfirmationModal: React.FC<CustomConfirmationModalProps> = ({
  open,
  title,
  message,
  cancelText = 'Cancelar', // Default to 'Cancelar' if not provided
  confirmText = 'Eliminar', // Default to 'Eliminar' if not provided
  onConfirm,
  onCancel,
  confirmButtonColor = 'confirm', // Default to 'confirm' if not provided
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const confirmButtonClass = confirmButtonColor === 'delete' ? classes.deleteButton : classes.confirmButton;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className={classes.modalContainer}
      PaperProps={{
        sx: {
          width: '656px',
          maxWidth: '90%',
          [theme.breakpoints.down('md')]: { // 'sm' breakpoint is for small screens
            width: '90%',
          },
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          className={classes.closeButton}
          sx={{ position: 'absolute', right: 15, top: 15 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider className={classes.blueDivider} />
      <Divider className={classes.grayDivider} />
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          {message}
        </DialogContentText>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onCancel} className={classes.cancelButton}>
            {cancelText}
          </Button>
          <Button onClick={onConfirm} className={confirmButtonClass} variant="contained">
            {confirmText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CustomConfirmationModal;
