/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode';
import { UserSchema } from '../requests/api/apiTypes';
import axios from '../utils/axios';
import { UNKNOWN_ERROR } from '../constants/errors';
import { INVALID_TOKEN, NO_TOKEN } from '../utils/errors';
import apiClient from '../requests/api/apiClient';

interface JWTToken {
  exp: number;
  id: string;
  user: UserSchema;
}

const getAccessToken = (): string => localStorage.getItem('accessToken') || '';

const setSession = (accessToken: string) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    apiClient.setSecurityData(`Bearer ${accessToken}`);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    apiClient.setSecurityData(null);
  }
};

const setAxiosInterceptors = ({ onLogout }: { onLogout: () => void }) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Logout directo a la pantalla de login solo se daria si es que backend manda
      // NO_TOKEN o INVALID_TOKEN
      if (error.response && (
        error.response.data.authorizationError === NO_TOKEN
        || error.response.data.authorizationError === INVALID_TOKEN
      )) {
        if (onLogout) {
          setSession('');
          onLogout();
        }
      }

      return Promise.reject(error);
    },
  );
};

const loginWithEmailAndPassword = async (email: string, password: string) => {
  const response = await apiClient.login.loginCreate({ email, password });
  let user;
  let token;

  if (response.data.success && response.data.user) {
    ({ token, user } = response.data);
    setSession(token || '');
    return {
      user,
      success: true,
    };
  }
  setSession('');
  return {
    success: false,
    errorMessage: response.data.message,
  };
};

const logoutSession = () => {
  setSession('');
};

const loginInWithToken = async () => {
  const accessToken = getAccessToken();
  const { id } = jwtDecode<JWTToken>(accessToken).user;
  try {
    const validId = id || null;
    if (!validId) {
      throw new Error('ID no es válido');
    }
    const { data } = await apiClient.users.usersDetail(validId.toString());
    if (!data.success) {
      logoutSession();
      return null;
    }
    return (data.user) || null;
  } catch (err) {
    return null;
  }
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<JWTToken>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleAuthentication = () => {
  // localStorage.clear();
  const accessToken = getAccessToken();
  if (!accessToken) {
    setSession('');
    return;
  }

  if (isValidToken(accessToken)) {
    setSession(accessToken);
  } else {
    setSession('');
  }
};

const register = async (
  name: string, lastname: string, email: string,
) => {
  const role = 'admin';
  try {
    const { data } = await apiClient.register.registerCreate({
      name, lastname, email, role,
    });

    if (data.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: data.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

const isAuthenticated = () => !!getAccessToken();

export default {
  setAxiosInterceptors,
  loginWithEmailAndPassword,
  loginInWithToken,
  logoutSession,
  handleAuthentication,
  isAuthenticated,
  setSession,
  register,
};
