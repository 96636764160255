import { Card, CardContent, Modal } from '@mui/material';
import React, { cloneElement } from 'react';
import useStyles from './styles';

type CustomModalData = {
  [key: string]: string | number | { [key: string]: string | number }[];
};

const CustomModal = ({
  children, open, handleClose, data, cardClassName,
}: {
  children: React.ReactElement,
  data?: CustomModalData,
  open: boolean,
  handleClose: React.Dispatch<React.SetStateAction<{ id: string, open: boolean }>> // revisar aca
  cardClassName?: string,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
    >
      <Card className={cardClassName}>
        <CardContent sx={{ height: '100%', width: '100%', display: 'flex' }}>
          {cloneElement(children, data ? { ...data } : undefined)}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CustomModal;
