import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import useStyles from '../styles';

const ListTile = ({
  title,
  tileInformation,
  isActive,
  onClick,
  customContainerClassName,
  seeMore,
  customSeeMoreIcon,
}: {
  title?: string,
  tileInformation: {
    [key: string]: string | number;
  },
  seeMore?: VoidFunction,
  isActive?: boolean,
  onClick?: () => void,
  customContainerClassName?: string
  customSeeMoreIcon?: JSX.Element,
}) => {
  const classes = useStyles();

  const auxTileInformation = Object.entries(tileInformation).map(([label, value]) => ({
    label,
    value,
  }));

  return (<Box
    className={isActive ? `${classes.activeListTileContainer} ${customContainerClassName}` : `${classes.listTileContainer} ${customContainerClassName}`}
  >
    <Box className={classes.listTileTextContainer} onClick={onClick || undefined}>
      {title && <Typography
        variant="h4"
      >
        {title}
      </Typography>}
      {auxTileInformation.map((info) => <Box key={info.label}>
        <Typography
          component='span'
          fontSize='12px'

        >
          {info.label}{': '}
        </Typography>
        <Typography
          component='span'
          fontSize='12px'
          fontWeight='bold'
        >
          {info.value}
        </Typography>
      </Box>)}
    </Box>
    {seeMore && <Box className={classes.listTileSeeMoreContainer}>
      <IconButton onClick={seeMore}><RemoveRedEye /></IconButton>
    </Box>}
    {customSeeMoreIcon && customSeeMoreIcon}
  </Box>
  );
};

export default ListTile;
