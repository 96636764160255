import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  tableHeaderStyle: {
    textAlign: 'center',
  },
  upsertVarietyModalContainer: {
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
  },
  upsertVarietyNameSpeciesContainer: {
    marginBottom: '20px',
    display: 'flex',

  },
  upsertVarietyModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  upsertVarietyModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerStyle: {
    margin: '24px 0px',
  },
  upsertVarietyModalButtonsContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableScrollbarContainer: {
    borderTop: '1px solid lightgray',
    borderBottom: '1px solid lightgray',
    marginTop: '20px',
    marginBottom: '10px',
    maxHeight: '220px',
  },
  tableHeaderCell: {
    fontWeight: 'bold',
  },
  datePicker: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '0px',
      borderBottom: '1px solid',
      borderBottomColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '0px',
    },
  },
}));

export default useStyles;
