import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { FetchError } from '../views/types';
import apiClient from '../requests/api/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiFunction = (...args: any[]) => Promise<{ data: any }>;
type ApiModel = {
  [endpoint: string]: ApiFunction;
};
type ApiClient = {
  [modelName: string]: ApiModel;
};

const typedApiClient = apiClient as unknown as ApiClient;

const useFetchApi = <T>({
  id,
  inputData,
  dependencies,
  modelName,
  modelEndpoint,
}: {
  id?: string;
  inputData?: { [key: string]: string | number };
  dependencies?: Array<boolean | number | string>;
  modelName: string;
  modelEndpoint: string;
}) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const apiFunction = typedApiClient[modelName]?.[modelEndpoint];
        if (!apiFunction) {
          throw new Error(`Endpoint ${modelEndpoint} not found in model ${modelName}`);
        }

        let dbData;
        if (id && inputData) {
          ({ data: dbData } = await apiFunction(id, inputData));
        } else if (id) {
          ({ data: dbData } = await apiFunction(id));
        } else if (inputData) {
          ({ data: dbData } = await apiFunction(inputData));
        } else {
          ({ data: dbData } = await apiFunction());
        }

        setData(dbData);
      } catch (err) {
        const { error } = err as FetchError;
        if (error?.message) {
          enqueueSnackbar(error?.message, { variant: 'error' });
        } else {
          const auxError = err as Error;
          enqueueSnackbar(auxError.message, { variant: 'error' });
        }
      } finally {
        setLoading(false);
      }
    };

    asyncFunc();
  }, dependencies || []);

  return { data, loading };
};

export default useFetchApi;
