import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  loadingPage: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '300px',
    height: '66px',
    backgroundColor: theme.palette.primary.main,
    padding: '5px',
  },
}), { name: 'useStyles' });

export default useStyles;
