import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
} from '@mui/material';
import Page from '../../components/Layout/Page';
import LoginForm from './LoginForm';
import AuthService from '../../services/authService';
import useStyles from './styles';
import { ReduxState } from '../../types';

function LoginView() {
  const classes = useStyles();
  const history = useHistory();
  const account = useSelector((state: ReduxState) => state.account);

  useEffect(() => {
    if (account?.user && AuthService.isAuthenticated()) {
      history.push('/usuarios');
    }
  }, [account.user]);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box className={classes.loginContainer}>
        <Box className={classes.loginFormContainer}>
          <Typography
            variant="h2"
            color="textPrimary"
          >
            Bienvienido a CDTEC IF
          </Typography>
          <Box mt={3} display='flex' justifyContent='center'>
            <LoginForm />
          </Box>
        </Box>
        <Box className={classes.imageContainer}>
          <img src='/img/login_background.png' alt="Login Background" className={classes.imageBackground} />
        </Box>
      </Box>
    </Page>
  );
}

export default LoginView;
