import React from 'react';
import {
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import useStyles from '../styles';

const steps = {
  n: { step: 0, label: 'Nula' },
  p: { step: 1, label: 'Aprobada' },
  a: { step: 2, label: 'En proceso' },
  c: { step: 3, label: 'Concluida' },
};

const WorkOrderStatusIndicator = ({ status }: { status: 'n' | 'p' | 'a' | 'c' | undefined }) => {
  const classes = useStyles();
  const activeStep = steps[status ?? 'n'].step;
  return (
    <Card className={classes.cardListProgressIndicatorContainer}>
      <CardContent >
        {activeStep === 0 && <Typography variant='body1'>
          Orden de trabajo nula
        </Typography>}
        {activeStep !== 0
          && <Stepper activeStep={activeStep} alternativeLabel>
            {Object.values(steps).slice(1).map((elem, index) => {
              const stepProps: { completed?: boolean } = {};
              if (activeStep > (index + 1)) {
                stepProps.completed = true;
              }
              return (
                <Step key={elem.step} {...stepProps}>
                  <StepLabel>{elem.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>}

      </CardContent>
    </Card >
  );
};

export default WorkOrderStatusIndicator;
