import {
  PostNodeDispatchGuideSchema,
  PostNodeSchema,
  PostSensorDispatchGuideSchema,
  PostSensorSchema,
} from '../../../../../requests/api/apiTypes';

export type DeviceInfo = {
  deviceType: string,
  farm: string,
  sectorId?: string,
  subSector?: string,
  dispatchGuideId?: string,
  comment?: string,
  brand?: string,
  model?: string,
  sensorId?: string,
  sensorType?: string,
  nodeId?: string,
  cdtecDeviceId?: string,
  isGatewayNode?: boolean,
  nodeChip?: string,
  nodeType?: string,
};

export type ErrorState = {
  deviceType: string;
  farm: string;
  sensorType: string;
  sensorId: string;
  nodeId: string;
  nodeType: string;
  cdtecDeviceId: string;
  nodeChip: string;
};

export const keysPostSensorSchema: (keyof PostSensorSchema)[] = [
  'deviceType',
  'farmId',
  'sectorId',
  'subSectorId',
  'dispatchGuideId',
  'cdtecDeviceId',
  'comment',
  'coordinates',
  'sensorType',
  'brand',
  'model',
  'nodeId',
];

export const keysPostNodeSchema: (keyof PostNodeSchema)[] = [
  'deviceType',
  'farmId',
  'sectorId',
  'subSectorId',
  'dispatchGuideId',
  'cdtecDeviceId',
  'comment',
  'coordinates',
  'nodeType',
  'brand',
  'model',
  'isGatewayNode',
  'nodeChip',
];

export const keysPostSensorDispatchGuideSchema: (keyof PostSensorDispatchGuideSchema)[] = [
  'deviceType',
  'farmId',
  'sectorId',
  'subSectorId',
  'dispatchGuideId',
  'comment',
  'coordinates',
  'nodeId',
  'sensorId',
];

export const keysPostNodeDispatchGuideSchema: (keyof PostNodeDispatchGuideSchema)[] = [
  'deviceType',
  'farmId',
  'sectorId',
  'subSectorId',
  'dispatchGuideId',
  'comment',
  'coordinates',
  'isGatewayNode',
  'nodeChip',
  'nodeId',
];
