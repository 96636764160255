import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from '@mui/styles';

const listTileContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px 10px',
  '&:hover': {
    cursor: 'pointer',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  containerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',
  },
  mainAreaContainer: {
    width: '100%',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  // WorkOrderList
  workOrderListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardListContainer: {
    height: '100%',
    minWidth: 270,
    display: 'flex',
  },
  allListTilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    padding: '0px 5px',
  },
  listTileContainer: {
    ...listTileContainerStyle,
  },
  activeListTileContainer: {
    ...listTileContainerStyle,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.white,
  },
  list: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: '5px 0px',
  },
  // ProgressIndicatorContainer
  cardListProgressIndicatorContainer: {
    width: '100%',
  },
  // Custom table scrollable
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '95%',
    paddingBottom: '5px',
  },
  tableHeader: {
    flex: '0 0 auto',
    position: 'sticky',
    top: '0',
    background: 'white',
    fontWeight: 'bold',
  },
  // Table requested devices
  requestedDevicesContainer: {
    flex: '3',
    marginTop: '20px',
    height: '100%',
  },
  // Dispatch guides
  dispatchGuideContainer: {
    flex: '2',
    marginTop: '20px',
    height: '100%',
  },
  dispatchGuideTableHeader: {
    textAlign: 'center',
  },
  // CustomDispatchGuideContent
  customDispatchGuideContentHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  customDispatchGuideContentHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customDispatchGuideContentContainer: {
    width: '680px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
