import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Slash as SlashIcon,
} from 'react-feather';
import { UserSchema } from '../../../../requests/api/apiTypes';

import useStyles from '../styles';
import { ListProps } from '../../../types';
import CustomModal from '../../../../components/General/CustomModal';
import CustomDeleteModalContent from '../../../../components/General/CustomDeleteModalContent';
import apiClient from '../../../../requests/api/apiClient';

type Filters = {
  account: { id: string, name: string },
  farm: { id: string, name: string }
};

interface UserListProps extends ListProps {
  loading: boolean,
  users: UserSchema[],
  accountsList: { id: string, name: string, farms?: { id: string, name: string }[] }[],
  farmList: { id: string, name: string }[],
  filter: Filters,
  setFilter: React.Dispatch<React.SetStateAction<Filters>>
}

const translate: { [key: string]: string } = {
  cdtec: 'CDTEC',
  consultant: 'Asesor',
  client: 'Cliente',
  multiAccountClient: 'Cliente Multicuenta',
  admin: 'Administrador',
};

const tableExtraPadding = '20px';

function Results({
  loading,
  users,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  countUsers,
  accountsList,
  farmList,
  filter,
  setFilter,
}: UserListProps) {
  const classes = useStyles();
  const [currentUsers, setCurrentUsers] = useState(users);
  const [numOfUsers, setNumOfUsers] = useState(countUsers);
  const [deleteModal, setDeleteModal] = useState({ open: false, id: '', userName: '' });
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    setCurrentUsers(users || []);
    setNumOfUsers(countUsers);
  }, [users, countUsers]);

  const handleDeleteFunction = async () => {
    setIsLoadingDelete(true);
    await apiClient.users.usersDelete([deleteModal.id]);
    // delete this user from currentUsers for inmediate delete effect
    setCurrentUsers((prevUsers) => prevUsers.filter((user) => user.id !== deleteModal.id));
    setIsLoadingDelete(false);
    setDeleteModal({ id: '', open: false, userName: '' });
  };

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const openDeleteModal = (id: string, userName: string) => {
    setDeleteModal({ id, open: true, userName });
  };

  const showNoDataOrLoading = () => (
    loading
      ? <Box display="flex" justifyContent="center" alignItems="center" height="100%" className={classes.noDataDiv}>
          <CircularProgress />
        </Box>
      : <Box display="flex" justifyContent="center" alignItems="center" height="100%" className={classes.noDataDiv}>
          <SvgIcon className={classes.iconContainer}>
            <SlashIcon />
          </SvgIcon>
        </Box>);

  return (
    <Card>
      <Box
        p={2}
        minHeight={56}
        display="flex"
        justifyContent="space-between"
        paddingRight={tableExtraPadding}
        paddingLeft={tableExtraPadding}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={'Buscar por nombre...'}
            value={query}
            label=' '
            variant='standard'
          />
        </Box>
        <Box display="flex" width='25%'>
          <Box display='flex' width='50%'>
            <Autocomplete
              fullWidth
              id="account-list-autocomplete"
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              options={accountsList || []}
              getOptionLabel={(option) => option?.name ?? ''}
              value={filter?.account || null}
              onChange={(e, value: { id: string, name: string } | null) => {
                setFilter((prevState) => ({
                  ...prevState,
                  account: { id: value?.id ?? '', name: value?.name ?? '' },
                }));
              }}
              renderInput={(params) => <TextField {...params} variant='standard' label="Cuenta" />}
            />
          </Box>
          <Box display='flex' width='50%' marginLeft='20px'>
            <Autocomplete
              fullWidth
              id="farm-list-autocomplete"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={farmList ?? []}
              getOptionLabel={(option) => option.name}
              value={filter?.farm || null}
              onChange={(e, value: { id: string, name: string } | null) => {
                setFilter((prevState) => ({
                  ...prevState,
                  farm: { id: value?.id ?? '', name: value?.name ?? '' },
                }));
              }}
              renderInput={(params) => <TextField {...params} variant='standard' label="Campo" />}
            />
          </Box>
        </Box>
      </Box>
      {currentUsers.length > 0 && !loading
        ? <>
          <PerfectScrollbar
            style={{ paddingLeft: tableExtraPadding, paddingRight: tableExtraPadding }}
          >
            <Box minWidth={700}>
              <Table>
                <TableHead>
                  <TableRow key='tablehead-key'>
                    <TableCell className={classes.tableHeaderText}>
                      Nombre
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Apellido
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Correo
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Teléfono
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Tipo
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Cuenta(s)
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Rol
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      Acciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUsers.map((user) => (
                    <TableRow
                      hover
                      key={user.id}
                    >
                      <TableCell>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {user.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {user.lastname}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {user.phoneNumber || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {translate[user?.type ?? '-'] || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="inherit"
                          variant="h6"
                        >
                          {user.accounts?.map((account) => account.name).join(', ') || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                      {translate[user?.role ?? '-'] || '-'}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          component={RouterLink}
                          to={`/usuarios/${user.id}/editar`}
                          size="large">
                          <SvgIcon fontSize="small">
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                        <IconButton onClick={() => openDeleteModal(user?.id ?? '', `${user.name} ${user.lastname}`)}>
                          <SvgIcon fontSize="small">
                            <DeleteIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={numOfUsers}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={Number(page)}
            rowsPerPage={Number(limit)}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={'Filas por página'}
            style={{ paddingLeft: tableExtraPadding, paddingRight: tableExtraPadding }}
          />
        </>
        : showNoDataOrLoading()
      }
      <CustomModal open={deleteModal.open} handleClose={() => setDeleteModal({ id: '', open: false, userName: '' })} data={{ id: deleteModal.id }}>
        <CustomDeleteModalContent
          title='Eliminar usuario'
          message={`¿Estás seguro de querer eliminar al usuario ${deleteModal.userName}?`}
          handleCloseModal={() => setDeleteModal({ id: '', open: false, userName: '' })}
          handleDeleteFunction={handleDeleteFunction}
          isLoadingDelete={isLoadingDelete}
        />
      </CustomModal>
    </Card>
  );
}

export default Results;
