import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import useStyles from './styles';

type ColorButton = 'blueButton' | 'greenButton' | 'lightBlueButton' | 'redButton';

const CustomButton = ({
  buttonText,
  color,
  onClick,
  disabled,
  size,
  setLoadingSpinnerWhenDisabled,
}: {
  buttonText: string,
  color?: 'blue' | 'green' | 'lightBlue' | 'red',
  onClick: VoidFunction,
  disabled?: boolean,
  size?: 'large' | 'small' | 'medium',
  setLoadingSpinnerWhenDisabled?: boolean,
}) => {
  const classes = useStyles();
  let colorButton: ColorButton = 'blueButton';

  if (color) {
    colorButton = `${color}Button`;
  }

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={classes[colorButton]}
      variant="contained"
      fullWidth
      size={size ?? 'medium'}
    >
      {(disabled && setLoadingSpinnerWhenDisabled) ? <CircularProgress size={24} /> : buttonText}
    </Button>
  );
};

export default CustomButton;
