import React, { useState } from 'react';
import {
  Box, Card, CircularProgress, ClassNameMap, Container, IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import Page from '../../components/Layout/Page';
import CustomSearch from '../../components/General/CustomSearch';
import CustomTable from '../../components/General/CustomTable';
import useStyles from './styles';
import CustomHeader from '../../components/General/CustomHeader';
import CustomButton from '../../components/General/CustomButton';
import CustomModal from '../../components/General/CustomModal';
import UpsertModalContent from './components/UpsertModalContent';
import apiClient from '../../requests/api/apiClient';
import { FetchError } from '../types';
import { CompanySchema, GetAccountsPerPageResponse } from '../../requests/api/apiTypes';
import useFetchApi from '../../hooks/useFetchApi';
import CustomDeleteModalContent from '../../components/General/CustomDeleteModalContent';

const ACCOUNTS_LIST_UI = [
  {
    label: 'Nombre', key: 'name',
  },
  {
    label: 'N° Empresas', key: 'numberOfCompanies',
  },
  {
    label: 'N° Campos', key: 'numberOfFarms',
  },
  {
    label: 'Acciones', key: 'actions',
  }];

const twoButtonsAction = (
  classes: ClassNameMap<string>,
  accountId: string,
  setUpsertModal: ({ open, id }: { open: boolean, id: string }) => void,
  setOpenDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box display='flex' alignItems='center' justifyContent='flex-start'>
  <IconButton onClick={() => setUpsertModal({ open: true, id: accountId })} >
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => setOpenDeleteModal({ open: true, id: accountId })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

const AccountListView = () => {
  const classes = useStyles();
  const [paginationParameters, setPaginationParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [query, setQuery] = useState('');
  const [upsertModal, setUpsertModal] = useState({ id: '', open: false });
  const [openDeleteModal, setOpenDeleteModal] = useState({ id: '', open: false });
  const [updateData, setUpdateData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: fetchData, loading } = useFetchApi<GetAccountsPerPageResponse>({
    modelName: 'accounts',
    modelEndpoint: 'accountsList',
    dependencies: [
      updateData, paginationParameters.page, paginationParameters.rowsPerPage, query,
    ],
    inputData: {
      page: paginationParameters.page,
      limit: paginationParameters.rowsPerPage,
      searchValue: query,
    },
  });
  const [isLoadingUpsert, setIsLoadingUpsert] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const localData = {
    count: fetchData?.totalAccounts ?? 0,
    values: fetchData?.accounts?.map((elem) => ({
      id: elem.id ?? uuidv4(),
      name: elem.name ?? '',
      numberOfCompanies: elem.numberOfCompanies ?? 0,
      numberOfFarms: elem.numberOfFarms ?? 0,
      actions: twoButtonsAction(
        classes,
        elem.id as string,
        setUpsertModal,
        setOpenDeleteModal,
      ),
    })) ?? [],
  };

  const handleCloseModal = () => {
    setUpsertModal({ id: '', open: false });
  };

  const handleDeleteCloseModal = () => {
    setOpenDeleteModal({ id: '', open: false });
  };

  const handleUpsertAccount = async ({
    name, companies, id,
  }: {
    name: string, companies: Pick<CompanySchema, 'id' | 'businessName' | 'rut'>[], id?: string,
  }) => {
    try {
      let response;
      setIsLoadingUpsert(true);
      if (id) {
        response = await apiClient.accounts.accountsUpdate(id, {
          name, companies: companies.map((elem) => elem.id as string),
        });
      } else {
        response = await apiClient.accounts.accountsCreate({
          name, companies: companies.map((elem) => elem.id as string),
        });
      }

      setUpdateData((prevState) => !prevState);
      handleCloseModal();
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setIsLoadingUpsert(false);
    }
  };

  const handleQuery = (valueToSearch: string) => {
    setQuery(valueToSearch);
    setPaginationParameters((prevState) => ({ ...prevState, page: 0 }));
  };

  const handleDeleteFunction = async ({ id }: { id: string }) => {
    try {
      setIsLoadingDelete(true);
      const { data } = await apiClient.accounts.accountsDelete(id);

      setUpdateData((prevState) => !prevState);
      handleDeleteCloseModal();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <Page
      className={classes.root}
      title='Cuentas'
    >
      <Container maxWidth={false}>
        <CustomHeader
          title='Cuentas'
          ActionButton={
            <CustomButton
              buttonText='Agregar cuenta'
              color='blue'
              onClick={() => setUpsertModal({ id: '', open: true })}
            />}
        />
        <Box mt={3}>
          {loading ? (
            <CircularProgress
              size={'3rem'}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '60%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : (
            <Card>
              <CustomSearch
                query={query}
                handleQuery={handleQuery}
              />
              <CustomTable
                rows={localData}
                headers={ACCOUNTS_LIST_UI}
                paginationParameters={paginationParameters}
                setPaginationParameters={setPaginationParameters}
              />
            </Card>
          )}
        </Box>
      </Container>
      <CustomModal open={upsertModal.open} handleClose={() => setUpsertModal({ id: '', open: false })} data={{ id: upsertModal.id }}>
        <UpsertModalContent
          handleCloseModal={handleCloseModal}
          handleUpsertFunction={handleUpsertAccount}
          isLoadingUpsert={isLoadingUpsert}
        />
      </CustomModal>
      <CustomModal open={openDeleteModal.open} handleClose={() => setOpenDeleteModal({ id: '', open: false })} data={{ id: openDeleteModal.id }}>
        <CustomDeleteModalContent
          title='Eliminar cuenta'
          message='¿Estás seguro de querer eliminar esta cuenta? No podras recuperar la información asociada a esta'
          handleCloseModal={handleDeleteCloseModal}
          handleDeleteFunction={handleDeleteFunction}
          isLoadingDelete={isLoadingDelete}
        />
      </CustomModal>
    </Page>
  );
};

export default AccountListView;
