import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import useStyles from '../styles';

type TableRowsInfo = {
  values: TableRows[];
  count: number;
};

type TableHeaders = {
  label: string;
  key: string;
  className?: string,
};

type TableRows = {
  className?: string,
  [key: string]: string | JSX.Element | number | undefined,
};

const CustomScrollableTable = ({
  containerClassName,
  title,
  headers,
  rows,
  noElementsMessage,
}: {
  containerClassName: string;
  title?: string;
  headers: TableHeaders[];
  rows: TableRowsInfo;
  noElementsMessage: string,
}) => {
  const classes = useStyles();
  return (
    <Card className={containerClassName}>
      <CardContent style={{ height: '100%' }}>
        {title && <Typography variant="h4" color="primary">
          {title}
        </Typography>}

        {rows.count === 0 && <Typography variant='body1'>
          {noElementsMessage}
        </Typography>}
        {rows.count > 0 && <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={`${uuidv4()}-${header.label}`} className={`${classes.tableHeader} ${header.className ?? ''}`.trim()}>
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.values.map((row) => (
                <TableRow key={`${uuidv4()}-${row.id}`} className={row.className}>
                  {headers.map((header) => (
                    <TableCell key={header.key}>{row[header.key]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}

      </CardContent>
    </Card>
  );
};

export default CustomScrollableTable;
