import React from 'react';
import {
  Box, InputAdornment, SvgIcon, TextField,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import useStyles from './styles';

const CustomSearch = ({ query, handleQuery }: {
  query: string,
  handleQuery: (value: string) => void,
}) => {
  const classes = useStyles();
  return (

    <Box
      className={classes.customSearchPositioning}
    >
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
              >
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={(event) => handleQuery(event.target.value)}
        placeholder={'Buscar por nombre...'}
        value={query}
        label=' '
        variant='standard'
      />
    </Box>

  );
};

export default CustomSearch;
