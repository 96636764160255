import { Dispatch } from 'redux';
import { LoginResponse } from '../requests/api/apiTypes';
import AuthService from '../services/authService';
import AppActions from './appActions';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';

export const loginSuccess = (userData: LoginResponse['user']) => async (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      userData,
    },
  });
};

export const setUserData = (userData: LoginResponse['user']) => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: SILENT_LOGIN,
    payload: {
      userData,
    },
  });
};

export const logout = () => async (dispatch: Dispatch<AppActions>) => {
  AuthService.logoutSession();

  dispatch({
    type: LOGOUT,
  });
};
