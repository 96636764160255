import { Api } from './apiTypes';

// Define a securityWorker function
const securityWorker = (token: string | null) => {
  if (token) {
    return {
      headers: {
        Authorization: token,
      },
    };
  }
  return {
    headers: {
      Authorization: '',
    },
  };
};

// Modify your apiClient initialization to include the securityWorker
const apiClient = new Api({
  baseUrl: '/api',
  securityWorker,
});

// Default env api token to access basic endpoints
apiClient.setSecurityData('');

export default apiClient;
