import { AccountSchema } from '../requests/api/apiTypes';

export const SET_USER_ACCOUNT = '@user-account/set-user-account';
export const RESET_USER_ACCOUNT = '@user-account/reset-user-account';

export const setUserAccount = (accountData: AccountSchema) => {
  sessionStorage.setItem('selectedAccount', JSON.stringify(accountData));
  return {
    type: SET_USER_ACCOUNT,
    payload: {
      ...accountData,
    },
  };
};

export const resetUserAccount = () => ({
  type: RESET_USER_ACCOUNT,
});
