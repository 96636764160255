import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  upsertAccountModalContainer: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  upsertAccountModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  upsertAccountModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerStyle: {
    margin: '24px 0px',
  },
  upsertAccountModalAutocomplete: {
    marginTop: '40px',
  },
  upsertAccountModalButtonsContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },

}));

export default useStyles;
