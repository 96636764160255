import {
  CDTEC_USER_TYPE,
  CONSULTANT_USER_TYPE,
  CLIENT_USER_TYPE,
  MULTI_ACCOUNT_CLIENT_USER_TYPE,
} from './user_types';

const fallbackRoutes:{ [key: string]: string } = {
  [CDTEC_USER_TYPE]: '/usuarios',
  [CONSULTANT_USER_TYPE]: '/usuarios',
  [CLIENT_USER_TYPE]: '/usuarios',
  [MULTI_ACCOUNT_CLIENT_USER_TYPE]: '/usuarios',
};

export default fallbackRoutes;
