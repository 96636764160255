// Every change here needs to be changed as well in server/misc/const/errors.ts
// Need to add in intl as well
// isAuthorized errors
const NO_TOKEN = 'Token no encontrado';
const INVALID_TOKEN = 'Fall al autenticar token';
const INVALID_ROLE = 'Rol inválido';

// Schema, Validations and Controllers errors
const REQUIRED_TOKEN = 'Token requerido';
const EXPIRED_LINK = 'Link expirado';
const PROHIBITED = 'Prohibido';
const USED_INVALID_TOKEN = 'Token ya utilizado o inválido';
const USER_INACTIVE = 'Usuario inactivo';
const USER_DOES_NOT_EXIST = 'Usuario no existe';
const WRONG_CREDENTIALS = 'Usuario y contraseña no coinciden';
const PASSWORDS_DO_NOT_MATCH = 'Las contraseñas no coinciden';
const TOKEN_NOT_GENERATED = 'Token no pudo ser generado';
const REQUIRED_EMAIL = 'Email requerido';
const USER_NOT_REGISTERED = 'Usuario no registrado';
const VERIFICATION_MAIL_ERROR = 'Error enviando email de verificación';
const GMAIL_USER_NOT_FOUND = 'Gmail: usuario no encontrado. Favor verificar';
const GMAIL_CONNECTION_ERROR = 'Error de conexion a gmail';
const USER_ALREADY_EXIST = 'Email ya existe';
const INVALID_ARGUMENT = 'Argumento inválido';
const NO_USERS_FOUND = 'No existen usuarios';
const LOGIN_WITH_EMAIL = 'Usuario creado sin Google. Usar login con correo y contraseña';
const INCORRECT_LOGIN = 'Login incorrecto';
// Default response for server error
const DEFAULT_API_ERROR_MESSAGE = 'Error de servidor';

export {
  NO_TOKEN,
  INVALID_TOKEN,
  INVALID_ROLE,
  REQUIRED_TOKEN,
  EXPIRED_LINK,
  PROHIBITED,
  USED_INVALID_TOKEN,
  USER_INACTIVE,
  USER_DOES_NOT_EXIST,
  WRONG_CREDENTIALS,
  PASSWORDS_DO_NOT_MATCH,
  TOKEN_NOT_GENERATED,
  REQUIRED_EMAIL,
  USER_NOT_REGISTERED,
  VERIFICATION_MAIL_ERROR,
  GMAIL_USER_NOT_FOUND,
  GMAIL_CONNECTION_ERROR,
  USER_ALREADY_EXIST,
  INVALID_ARGUMENT,
  NO_USERS_FOUND,
  LOGIN_WITH_EMAIL,
  INCORRECT_LOGIN,
  DEFAULT_API_ERROR_MESSAGE,
};
