import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useStyles from '../styles';
import CustomButton from '../../../../components/General/CustomButton';
import apiClient from '../../../../requests/api/apiClient';
import { FetchError } from '../../../types';
import { ReduxState } from '../../../../types';
import { WorkOrderSchema } from '../../../../requests/api/apiTypes';
import { formatLocaleDate } from '../../../../utils/formatDate';

type PropData = {
  isActive: boolean,
  handleSelectTile: (workOrderId: string) => void,
  data: {
    orderName: string,
    orderId: string,
    createdAt: string,
    price?: string,
    farmName?: string,
    status: string,
  }
};

const PARSE_STATUS = {
  c: 'Concluida',
  a: 'Aprobada',
  p: 'Pendiente',
};

const ListTile = ({ data, handleSelectTile, isActive }: PropData) => {
  const classes = useStyles();
  return (<Box
    className={isActive ? classes.activeListTileContainer : classes.listTileContainer}
    onClick={() => handleSelectTile(data.orderId)}

  >
    <Typography
      variant="h4"
    >
      {`Orden ${data.orderName}`}
    </Typography>
    <Box>
      <Typography
        component='span'
        fontSize='12px'

      >
        Fecha de creación: {' '}
      </Typography>
      <Typography
        component='span'
        fontSize='12px'
        fontWeight='bold'
      >
        {data.createdAt}
      </Typography>
    </Box>
    {/* <Box>
      <Typography
        component='span'
        fontSize='12px'

      >
        Precio: {' '}
      </Typography>
      <Typography
        component="span"
        fontSize='12px'
        fontWeight='bold'
      >
        {data.price}
      </Typography>
    </Box> */}
    {/* <Box>
      <Typography
        component='span'
        fontSize='12px'

      >
        Campo: {' '}
      </Typography>
      <Typography
        component="span"
        fontSize='12px'
        fontWeight='bold'
      >
        {data.farmName}
      </Typography>
    </Box> */}
    <Box>
      <Typography
        component='span'
        fontSize='12px'

      >
        Estado: {' '}
      </Typography>
      <Typography
        component="span"
        fontSize='12px'
        fontWeight='bold'
      >
        {PARSE_STATUS[data.status as 'c' | 'a' | 'p']}
      </Typography>
    </Box>
  </Box>
  );
};

const WorkOrderList = ({
  workOrders,
  selectedWorkOrder,
  setSelectedWorkOrder,
  setFetchAgain,
  searchValue,
  setSearchValue,
}: {
  searchValue: string,
  setSearchValue: React.Dispatch<React.SetStateAction<string>>,
  workOrders: WorkOrderSchema[],
  selectedWorkOrder: string | null,
  setSelectedWorkOrder: React.Dispatch<React.SetStateAction<string | null>>,
  setFetchAgain: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);

  const updateWorkOrders = async () => {
    try {
      const response = await apiClient.workOrdersFromSoftland.workOrdersFromSoftlandUpdate({
        accountId: selectedAccount?.id,
      });
      enqueueSnackbar(response.data.message, { variant: 'success' });
      setFetchAgain((prevState) => !prevState);
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      if (fetchError?.message) {
        enqueueSnackbar(fetchError?.message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    }
  };

  const handleSelectTile = (workOrderId: string) => {
    setSelectedWorkOrder(workOrderId);
  };

  return (
    <Card className={classes.cardListContainer}>
      <CardContent >
        <Box className={classes.list}>
          <Box alignItems="flex-start" mb={1}>
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              size='small'
              label='Buscar por código...'
              InputProps={{ endAdornment: <Search /> }} />
          </Box>
          <Box mb={1}>
            <CustomButton buttonText='actualizar' onClick={() => updateWorkOrders()} />
          </Box>
          <Box className={classes.allListTilesContainer}>
            <Divider />
            {workOrders.length > 0 && workOrders.map((elem) => (
              <React.Fragment
                key={elem.id}
              >
                <ListTile
                  isActive={selectedWorkOrder === elem.id}
                  handleSelectTile={handleSelectTile}
                  data={{
                    orderId: elem?.id ?? uuidv4(),
                    orderName: elem?.name ?? 'Sin Orden',
                    createdAt: elem?.softlandCreationDate ? formatLocaleDate(elem.softlandCreationDate) : 'Sin fecha',
                    status: elem?.status ?? 'Sin estado',
                  }} />
                <Divider />
              </React.Fragment>))}
            {workOrders.length === 0 && <Typography variant='body1'>
              No hay ordenes de trabajo disponibles
            </Typography>}
          </Box>
        </Box>
      </CardContent>
    </Card >
  );
};

export default WorkOrderList;
