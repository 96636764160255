import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import useStyles from './styles';

interface FullScreenImageModalProps {
  open: boolean;
  imageUrl: string;
  onClose: () => void;
}

const FullScreenImageModal: React.FC<FullScreenImageModalProps> = ({ open, imageUrl, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      className={classes.fullScreenModal}
    >
      <DialogContent className={classes.fullScreenModalContent}>
        <IconButton
          onClick={onClose}
          className={classes.closeButton}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={imageUrl}
          alt="Imagen ampliada"
          className={classes.fullScreenImage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FullScreenImageModal;
