/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Divider,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CustomConfirmationModal from '../../../../../components/General/CustomConfirmationModal';
import useStyles from '../styles';
import formatDate from '../../../../../utils/formatDate';
import apiClient from '../../../../../requests/api/apiClient';
import { FetchError } from '../../../../types';

const deviceStatuses = [
  'Operativo',
  'En proceso de reparación',
  'Fuera de línea',
];

interface DeviceInfoProps {
  installationDate: string;
  status: string;
  comment?: string;
  deviceId: string;
}

const DeviceInfo = ({
  installationDate,
  status,
  comment,
  deviceId,
}: DeviceInfoProps) => {
  const classes = useStyles();
  const [deviceStatus, setDeviceStatus] = useState(status);
  const [modalOpen, setModalOpen] = useState(false);
  const handleStatusChange = (newStatus: string) => {
    setDeviceStatus(newStatus);
    setModalOpen(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = async () => {
    try {
      const { data } = await apiClient.devices.devicesUpdate({
        status: deviceStatus,
      }, deviceId);

      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      enqueueSnackbar(fetchError.message, { variant: 'error' });
    }
    setModalOpen(false);
  };

  const handleCancel = () => {
    setDeviceStatus(status); // Revert status to original if action is canceled
    setModalOpen(false);
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gap='19px'>
        <Card className={classes.cardLayoutContainer}>
          <CardContent className={classes.cardLayoutContentContainer}>
            <Box display='flex' className={classes.boxContentContainer}>
              <Typography
                variant="h5"
                color="primary"
              >
                Fecha de instalación
              </Typography>
              <Divider className={classes.divider} />
              <Typography
                variant="h5"
              >
                { formatDate(installationDate) }
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Card className={classes.cardLayoutContainer}>
          <CardContent className={classes.cardLayoutContentContainer}>
            <Box display='flex' className={classes.boxContentContainer}>
              <Typography
                variant="h5"
                color="primary"
              >
                Estado
              </Typography>
              <Divider className={classes.divider} />
              <TextField
                value={deviceStatus}
                onChange={(e) => handleStatusChange(e.target.value)}
                variant='standard'
                fullWidth
                select
              >
                {deviceStatuses
                  .map((elem) => (<MenuItem key={elem} value={elem}>
                  {elem}
                </MenuItem>))}
              </TextField>
            </Box>
          </CardContent>
        </Card>
        <Card className={classes.cardLayoutContainer}>
          <CardContent className={classes.cardLayoutContentContainer}>
            <Box display='flex' className={classes.boxContentContainer}>
              <Typography
                variant="h5"
                color="primary"
              >
                Comentario
              </Typography>
              <Divider className={classes.divider} />
              <Typography
                variant="h6"
                color="textSecondary"
              >
                { comment }
              </Typography>
              <Divider className={classes.divider} />
            </Box>
          </CardContent>
        </Card>
        <CustomConfirmationModal
          open={modalOpen}
          title="Cambiar Estado"
          message={`¿Estás seguro de que quieres cambiar el estado a ${deviceStatuses.find((s) => s === deviceStatus)}?`}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          confirmButtonColor="confirm"
          confirmText='Confirmar'
        />
      </Box>
    </>
  );
};

export default DeviceInfo;
