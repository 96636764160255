import { useSelector } from 'react-redux';
import React from 'react';
import { Redirect } from 'react-router';
import { ChildrenProps, ReduxState } from '../../types';
import AuthService from '../../services/authService';

function ClientAuthGuard({ children, admittedUserTypes }: ChildrenProps
& { admittedUserTypes?: string[] }): JSX.Element {
  const account = useSelector((state: ReduxState) => state.account);
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  if (!account.user) {
    return <Redirect to="/login" />;
  }
  AuthService.handleAuthentication();
  if (!AuthService.isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  if (!selectedAccount) {
    return <Redirect to="/login" />;
  }

  // Si uno desea no tener un guard via roles, simplemente no definir roles
  if (admittedUserTypes && !admittedUserTypes.includes(account.user.type || '')) {
    return <Redirect to="/sin-autorizacion" />;
  }

  return <>{children}</>;
}

export default ClientAuthGuard;
