import React, { useState } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Container,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../components/Layout/Page';
import CustomTable from '../../components/General/CustomTable';
import useStyles from './styles';
import CustomSearch from '../../components/General/CustomSearch';
import CustomHeader from '../../components/General/CustomHeader';
import useFetchApi from '../../hooks/useFetchApi';
import { GetDevicesPerPageResponse } from '../../requests/api/apiTypes';

const DEVICES_LIST_UI = [
  {
    label: 'Nombre', key: 'deviceName',
  },
  {
    label: 'Código producto', key: 'deviceModel',
  },
  {
    label: 'Serie', key: 'serie',
  },
];

const DevicesListView = () => {
  const classes = useStyles();
  const [paginationParameters, setPaginationParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [query, setQuery] = useState('');
  const handleQuery = (valueToSearch: string) => {
    setQuery(valueToSearch);
    setPaginationParameters((prevState) => ({ ...prevState, page: 0 }));
  };

  const { data: fetchData, loading } = useFetchApi<GetDevicesPerPageResponse>({
    modelEndpoint: 'devicesList',
    modelName: 'devices',
    dependencies: [
      paginationParameters.page, paginationParameters.rowsPerPage, query,
    ],
    inputData: {
      page: paginationParameters.page,
      limit: paginationParameters.rowsPerPage,
      searchValue: query,
    },
  });

  const localData = {
    count: fetchData?.totalDevices ?? 0,
    values: fetchData?.devices?.map((elem) => ({
      id: elem.id ?? uuidv4(),
      deviceName: elem.deviceModel?.name,
      deviceModel: elem.deviceModel?.codProd,
      serie: elem.serie ?? 'N/A',
    })) ?? [],
  };

  return (
    <Page
      className={classes.root}
      title='Equipos'
    >
      <Container maxWidth={false}>
        <CustomHeader title='Equipos' />
        <Box mt={3}>
          {loading ? (
            <CircularProgress
              size={'3rem'}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '60%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : (
            <Card>
              <CustomSearch query={query} handleQuery={handleQuery} />
              <CustomTable
                rows={{ values: localData.values, count: localData.count }}
                headers={DEVICES_LIST_UI}
                paginationParameters={paginationParameters}
                setPaginationParameters={setPaginationParameters}
              />
            </Card>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default DevicesListView;
