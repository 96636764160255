import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import useStyles from '../styles';

const ListLayout = ({ children } : { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardListContainer}>
      <CardContent className={classes.cardListContentContainer}>
        <Box className={classes.list}>
          {children}
        </Box>
      </CardContent>
    </Card >
  );
};

export default ListLayout;
