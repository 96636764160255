/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { FarmSectorCustomAction, FarmSectorState, SensorNode } from '../types';
import {
  SET_ALL_FARM_DEVICES, SET_FARM_DEVICE, SET_FARM_SECTOR, SET_SENSOR_NODE_FILTER,
} from '../actions/farmSectorActions';
import { DeviceSchema } from '../requests/api/apiTypes';

const initialState: FarmSectorState = {
  selectedSector: '',
  selectedDevice: '',
  allFarmDevices: [],
  sensorNodeFilter: {
    deviceType: 'node',
    filterValue: '',
  },
};

const farmSectorReducer = (
  state = initialState,
  action: FarmSectorCustomAction,
): FarmSectorState => {
  switch (action.type) {
    case SET_FARM_SECTOR: {
      return produce(state, (draft: Draft<FarmSectorState>) => {
        draft.selectedSector = action.payload as string;
      });
    }

    case SET_FARM_DEVICE: {
      return produce(state, (draft: Draft<FarmSectorState>) => {
        draft.selectedDevice = action.payload as string;
      });
    }

    case SET_ALL_FARM_DEVICES: {
      return produce(state, (draft: Draft<FarmSectorState>) => {
        draft.allFarmDevices = [...action.payload as Array<DeviceSchema>];
      });
    }

    case SET_SENSOR_NODE_FILTER: {
      return produce(state, (draft: Draft<FarmSectorState>) => {
        draft.sensorNodeFilter = action.payload as SensorNode;
      });
    }

    default: {
      return state;
    }
  }
};

export default farmSectorReducer;
