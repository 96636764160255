import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import apiClient from '../../requests/api/apiClient';
import useStyles from './styles';
import { ClassNameProps, FetchError } from '../types';
import authService from '../../services/authService';
import { loginSuccess } from '../../actions/accountActions';

function LoginForm({ className, ...rest }: ClassNameProps) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (email: string) => {
    if (!email) {
      enqueueSnackbar('Debe ingresar un email.', { variant: 'error' });
      return;
    }

    try {
      const response = await apiClient.recoverPassword.recoverPasswordCreate({ email });
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().matches(new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i), 'Formato de email inválido').required('Correo requerido'),
        password: Yup.string().max(255).required('Contraseña requerida'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          const response = await authService.loginWithEmailAndPassword(
            values.email, values.password,
          );
          if (response.success) {
            dispatch(loginSuccess(response.user));
          }
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            color="secondary"
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label={'Correo'}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="standard"
            sx={{ maxWidth: '475px' }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="secondary"
            helperText={touched.password && errors.password}
            label={'Contraseña'}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="standard"
            sx={{ maxWidth: '475px' }}
          />
          <Box mt={2} maxWidth={475}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Iniciar sesión
            </Button>
          </Box>
          <Button
            className={classes.resetPassword}
            onClick={() => handleResetPassword(values.email)}
          >
            <Typography
              color='primary'
            >
              ¿Olvidaste tu contraseña?
            </Typography>

          </Button>
          {/* TODO: Descomentar si se permitirá el registro de usuarios fuera del Dashboard
          y crear una ruta que acepte password */}
          {/* <a href="/register">Registarse</a> */}
        </form>
      )
      }
    </Formik >
  );
}

export default LoginForm;
