import React from 'react';
import {
  Box, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

const MonthDayPicker = ({
  value,
  onChange,
  label,
  error = false,
  helperText = '',
}: {
  value: string,
  onChange: (value: string) => void,
  label: string,
  error?: boolean,
  helperText?: string,
}) => {
  const [month, day] = value ? value.split('-').map(Number) : [null, null];

  const getDaysInMonth = (monthNum: number | null) => {
    if (!monthNum) return 31;

    const daysPerMonth = {
      2: 28, // February
      4: 30, // April
      6: 30, // June
      9: 30, // September
      11: 30, // November
      // All other months have 31 days
    };

    return daysPerMonth[monthNum as keyof typeof daysPerMonth] || 31;
  };

  const handleMonthChange = (newMonth: number) => {
    const maxDays = getDaysInMonth(newMonth);
    const adjustedDay = day && day > maxDays ? maxDays : day;

    const newValue = `${newMonth.toString().padStart(2, '0')}-${adjustedDay?.toString().padStart(2, '0') || '01'}`;
    onChange(newValue);
  };

  const handleDayChange = (newDay: number) => {
    const newValue = `${month?.toString().padStart(2, '0') || '01'}-${newDay.toString().padStart(2, '0')}`;
    onChange(newValue);
  };

  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const days = Array.from({ length: getDaysInMonth(month) }, (_, i) => i + 1);

  return (
    <Box>
      <Typography variant="caption" color={error ? 'error' : 'textSecondary'}>
        {label}
      </Typography>
      <Box display="flex" alignItems="flex-end">
        <FormControl variant="standard" error={error} sx={{ minWidth: 80, mr: 1 }}>
          <InputLabel>Mes</InputLabel>
          <Select
            value={month || ''}
            onChange={(e) => handleMonthChange(e.target.value as number)}
          >
            {months.map((m) => (
              <MenuItem key={m} value={m}>{m}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" error={error} sx={{ minWidth: 80 }}>
          <InputLabel>Día</InputLabel>
          <Select
            value={day || ''}
            onChange={(e) => handleDayChange(e.target.value as number)}
          >
            {days.map((d) => (
              <MenuItem key={d} value={d}>{d}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {error && helperText && (
        <Typography color="error" variant="caption">{helperText}</Typography>
      )}
    </Box>
  );
};

export default MonthDayPicker;
