import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CustomConfirmationModal from '../../../../../components/General/CustomConfirmationModal';
import FullScreenImageModal from '../../../../../components/General/FullScreenImageModal';
import useStyles from '../styles';
import useFetchApi from '../../../../../hooks/useFetchApi';
import { GetGoogleStorageImagesResponse } from '../../../../../requests/api/apiTypes';
import apiClient from '../../../../../requests/api/apiClient';
import { FetchError } from '../../../../types';

const ImagesContainer = ({ deviceId }: { deviceId: string }) => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  }, []);

  const [updateImages, setUpdateImages] = useState(false);

  const { data: imageData } = useFetchApi<GetGoogleStorageImagesResponse>({
    modelName: 'images',
    modelEndpoint: 'listList',
    inputData: { deviceId },
    dependencies: [updateImages],
  });

  const [images, setImages] = useState<GetGoogleStorageImagesResponse['images']>(imageData ? imageData.images : []);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [fullScreenModalOpen, setFullScreenModalOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (imageData?.images) {
      setImages(imageData.images);
    }
  }, [imageData]);

  const fetchImages = async () => {
    try {
      setUpdateImages(!updateImages);
      setCurrentImageIndex(0); // Resetear al primer índice después de la actualización
    } catch (error) {
      // handle error later
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    ));
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    ));
  };

  const handleConfirmDelete = async () => {
    const { fileName } = images[currentImageIndex];

    try {
      if (fileName) {
        const { data } = await apiClient.images.deleteDelete({ deviceId, fileName });
        enqueueSnackbar(data.message, { variant: 'success' });
      } else {
        enqueueSnackbar('No se pudo eliminar la imagen', { variant: 'error' });
      }

      // Volver a obtener las imágenes después de eliminar una
      await fetchImages();
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      enqueueSnackbar(fetchError.message, { variant: 'error' });
    }
    setModalOpen(false);
  };

  const handleAddImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('deviceId', deviceId);

      const { data } = await apiClient.images.uploadCreate({
        deviceId,
        file,
      });

      enqueueSnackbar(data.message, { variant: 'success' });
      // Volver a obtener las imágenes después de subir una nueva
      await fetchImages();
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      enqueueSnackbar(fetchError.message, { variant: 'error' });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      handleFileUpload(event.target.files[0]);
    }
  };

  const handleImageClick = () => {
    setFullScreenModalOpen(true); // Abre el modal en pantalla completa
  };

  return (
    <Card className={classes.cardImagesContainer}>
      <CardContent className={classes.cardImagesContentContainer}>
        <Box className={classes.imagesHeaderContainer}>
          <Typography variant="h5" color="primary">Imágenes</Typography>
          <div>
            <IconButton
              onClick={handleAddImage}
              className="addImageButton"
              sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }}
            >
              <AddIcon />
            </IconButton>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </Box>
        <Divider className={classes.imagesDivider} />

        {images.length > 0 ? (
          <>
            <Box className={classes.imageMainContainer}>
              <IconButton
                onClick={handlePreviousImage}
                className={classes.navButtonLeft}
                sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }}
              >
                <ArrowBackIcon />
              </IconButton>
              <img
                src={images[currentImageIndex].url}
                alt={`Imagen ${currentImageIndex + 1}`}
                className={classes.mainImage}
                onClick={handleImageClick}
              />
              <IconButton
                onClick={handleNextImage}
                className={classes.navButtonRight}
                sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }}
              >
                <ArrowForwardIcon />
              </IconButton>
              <IconButton
                onClick={() => setModalOpen(true)}
                className={classes.deleteButton}
                sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box className={classes.thumbnailContainer}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image.url}
                  alt={`Thumbnail ${index + 1}`}
                  className={`${classes.thumbnail} ${
                    index === currentImageIndex ? classes.activeThumbnail : ''
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="textSecondary">
            No hay imágenes para mostrar.
          </Typography>
        )}
      </CardContent>
      <CustomConfirmationModal
        open={modalOpen}
        title="Eliminar Imagen"
        message={'¿Estás seguro de que quieres eliminar esta imagen?'}
        onConfirm={handleConfirmDelete}
        onCancel={() => setModalOpen(false)}
        confirmButtonColor="delete"
        confirmText='Eliminar'
      />
      <FullScreenImageModal
        open={fullScreenModalOpen}
        imageUrl={images[currentImageIndex]?.url || ''}
        onClose={() => setFullScreenModalOpen(false)}
      />
    </Card>
  );
};

export default ImagesContainer;
