import React, { useState } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Container,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { format } from 'rut.js';
import Page from '../../components/Layout/Page';
import CustomTable from '../../components/General/CustomTable';
import useStyles from './styles';
import CustomSearch from '../../components/General/CustomSearch';
import CustomHeader from '../../components/General/CustomHeader';
import CustomButton from '../../components/General/CustomButton';
import apiClient from '../../requests/api/apiClient';
import { FetchError } from '../types';
import useFetchApi from '../../hooks/useFetchApi';
import { GetCompaniesPerPageResponse } from '../../requests/api/apiTypes';

const COMPANY_LIST_UI = [
  {
    label: 'Fecha creación', key: 'createdAt',
  },
  {
    label: 'Razón social', key: 'businessName',
  },
  {
    label: 'RUT', key: 'rut',
  },
  {
    label: 'País', key: 'country',
  },
  {
    label: 'Región', key: 'region',
  },
  {
    label: 'Comuna', key: 'commune',
  },
  {
    label: 'Dirección', key: 'address',
  },
];

const CompanyListView = () => {
  const classes = useStyles();
  const [paginationParameters, setPaginationParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [query, setQuery] = useState('');
  const [updateData, setUpdateData] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleQuery = (valueToSearch: string) => {
    setQuery(valueToSearch);
    setPaginationParameters((prevState) => ({ ...prevState, page: 0 }));
  };

  const { data: fetchData, loading } = useFetchApi<GetCompaniesPerPageResponse>({
    modelEndpoint: 'companiesList',
    modelName: 'companies',
    dependencies: [
      updateData, paginationParameters.page, paginationParameters.rowsPerPage, query,
    ],
    inputData: {
      page: paginationParameters.page,
      limit: paginationParameters.rowsPerPage,
      searchValue: query,
    },
  });

  const localData = {
    count: fetchData?.totalCompanies ?? 0,
    values: fetchData?.companies?.map((elem) => ({
      id: elem.id ?? uuidv4(),
      createdAt: new Date(elem.createdAt).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      name: elem.name,
      rut: format(elem.rut),
      businessName: elem.businessName,
      country: elem.country,
      region: elem.region,
      commune: elem.commune,
      address: elem.address,
    })) ?? [],
  };

  const updateCompanies = async () => {
    try {
      setIsButtonLoading(true);
      const { data } = await apiClient.companies.softlandGetAllCompaniesUpdate();

      setUpdateData((prevState) => !prevState);
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <Page
      className={classes.root}
      title='Empresas'
    >
      <Container maxWidth={false}>
        <CustomHeader
          title='Empresas'
          ActionButton={
            <CustomButton
              buttonText='Actualizar empresas'
              color='blue'
              onClick={updateCompanies}
              disabled={isButtonLoading}
              setLoadingSpinnerWhenDisabled={true}
            />
          }
        />
        <Box mt={3}>
          {loading ? (
            <CircularProgress
              size={'3rem'}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '60%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : (
            <Card>
              <CustomSearch query={query} handleQuery={handleQuery} />
              <CustomTable
                rows={{ values: localData.values, count: localData.count }}
                headers={COMPANY_LIST_UI}
                paginationParameters={paginationParameters}
                setPaginationParameters={setPaginationParameters}
              />
            </Card>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default CompanyListView;
