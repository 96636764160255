/* eslint-disable @typescript-eslint/default-param-last */
import produce, { Draft } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { UserCustomAction, UserState } from '../types';
import { SET_CREATE_USER_DATA, RESET_CREATE_USER_DATA } from '../actions/userActions';
import { PostUserSchema } from '../requests/api/apiTypes';

const initialState: UserState = {
  user: null,
};

const userReducer = (state = initialState, action: UserCustomAction): UserState => {
  switch (action.type) {
    case SET_CREATE_USER_DATA: {
      return produce(state, (draft: Draft<UserState>) => {
        draft.user = draft.user || ({} as PostUserSchema);

        Object.entries(action.payload).forEach(([key, value]) => {
          const validKey = key as keyof PostUserSchema;
          ((draft.user as WritableDraft<PostUserSchema>)[validKey] as typeof value) = value;
        });
      });
    }

    case RESET_CREATE_USER_DATA: {
      return produce(state, (draft: Draft<UserState>) => {
        draft.user = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
