import React, { useState } from 'react';
import {
  Box,
  Container,
  Tabs,
  Tab,
} from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../../components/General/CustomHeader';
import ImagesContainer from './component/ImagesContainer';
import DeviceInfo from './component/DeviceInfo';
import useFetchApi from '../../../../hooks/useFetchApi';
import { GetDeviceByIdResponse } from '../../../../requests/api/apiTypes';
import CustomButton from '../../../../components/General/CustomButton';
import CustomConfirmationModal from '../../../../components/General/CustomConfirmationModal';
import { FetchError } from '../../../types';
import apiClient from '../../../../requests/api/apiClient';
import { ReduxState } from '../../../../types';
import { setAllFarmDevices, setFarmDevice } from '../../../../actions/farmSectorActions';

const DevicesView = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('instalacion');
  const { id: deviceId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { allFarmDevices } = useSelector((state: ReduxState) => state.farmSector);
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: fetchData } = useFetchApi<GetDeviceByIdResponse>({
    modelEndpoint: 'devicesDetail',
    modelName: 'devices',
    id: deviceId,
  });

  const localData = {
    deviceName: fetchData?.device?.deviceModel?.name,
    installationDate: fetchData?.device?.createdAt,
    status: fetchData?.device?.status,
    deviceId: fetchData?.device?.id,
    comment: fetchData?.device?.comment,
  };

  const handleDeleteDevice = async () => {
    try {
      await apiClient.devices.devicesDelete(deviceId);
      enqueueSnackbar('Device eliminado con éxito', { variant: 'success' });
      setDeleteModalOpen(false);
      // delete devices from redux
      const allFarmDevicesFiltered = allFarmDevices.filter((device) => device.id !== deviceId);
      dispatch(setAllFarmDevices(allFarmDevicesFiltered));
      dispatch(setFarmDevice(''));

      history.push('/layout');
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Page
      className={classes.root}
      title='Layout'
    >
      <Container maxWidth={false} className={classes.containerContainer}>
        <CustomHeader
          title={`Layout / ${localData.deviceName}`}
          TabsComponent={
            <Tabs
              value={activeTab}
              onChange={(e, value) => setActiveTab(value)}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab value="graficos" label="Gráficos" />
              <Tab value="instalacion" label="Instalación" />
            </Tabs>
          }
        />
        <Box display='flex' className={classes.mainBoxContainer}>
          {activeTab === 'instalacion'
            ? <>
              <Box className={classes.box}>
                {localData.deviceId ? (
                  <ImagesContainer
                    deviceId={localData.deviceId}
                  />
                ) : < div>Loading device...</div>
                }
              </Box>
              <Box className={classes.box}>
                {localData.deviceId && localData.installationDate && localData.status ? (
                  <DeviceInfo
                    deviceId={localData.deviceId}
                    installationDate={localData.installationDate}
                    status={localData.status}
                    comment={localData.comment}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </Box>
            </>
            : <>
              <h1>Graficos</h1>
            </>
          }
        </Box>
        <Box className={classes.deleteDeviceButton}>
          <CustomButton
            buttonText='Eliminar Dispositivo'
            color='red'
            onClick={() => setDeleteModalOpen(true)}
          />
        </Box>
      </Container>
      <CustomConfirmationModal
        open={deleteModalOpen}
        title="Eliminar Dispositivo"
        message="¿Estás seguro de que quieres eliminar este dispositivo?"
        onConfirm={handleDeleteDevice}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButtonColor="delete"
        confirmText='Eliminar'
      />
    </Page>
  );
};

export default DevicesView;
