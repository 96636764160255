import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import apiClient from '../../requests/api/apiClient';
import useStyles from './styles';
import { FetchError } from '../types';

export interface PasswordFormProps {
  className?: string;
  token: string;
}

function SetPasswordForm({
  className, token, ...rest
}: PasswordFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // TODO: mejorar mensajes de error desde api y acá
  // En general revisar toda la app
  const onSubmitSuccess = () => {
    history.push('/login');
  };

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required('Contraseña requerida'),
        confirmPassword: Yup.string().max(255).required('Confirmación de contraseña requerida'),
      })}
      onSubmit={async (values, {
        setStatus,
        setSubmitting,
      }) => {
        try {
          const data = {
            token,
            password: values.password,
            confirmPassword: values.confirmPassword,
          };
          const res = await apiClient.resetPassword.resetPasswordCreate(data);

          enqueueSnackbar(res.data.message, { variant: 'success' });
          setStatus({ success: true });
          setSubmitting(false);
          onSubmitSuccess();
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(classes.rootSetPasswordForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            autoFocus
            helperText={touched.password && errors.password}
            label="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="standard"
          />
          <TextField
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirmar contraseña"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="standard"
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Actualizar contraseña
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default SetPasswordForm;
